import { Button } from 'flowbite-react';
import React from 'react';

import { PageHeader } from '../components/PageHeader';
import { useGlobalState } from '../context/AppContext';
import { Dashboard } from '../features/DashboardNew/DashboardNew';
import { useFlyoutNavigate } from '../hooks/useFlyoutNavigate';

const CompanyLogoComponent = () => {
  const { user } = useGlobalState();
  const { navigate } = useFlyoutNavigate();

  if (user?.companyLogo) {
    return (
      <div
        style={{
          maxWidth: 120,
        }}
      >
        <img src={user.companyLogo} />
      </div>
    );
  }

  if (!user?.roles?.includes('Owner')) return null;

  return (
    <Button
      color="light"
      onClick={() => navigate('edit-company')}
      className="items-center font-normal"
    >
      Upload Logo
    </Button>
  );
};

export const DashboardNew: React.FC = () => {
  const { user } = useGlobalState();
  return (
    <div className="container px-4 py-8">
      <PageHeader
        breadcrumbs={[{ to: '/dashboard', text: 'Hi ' + user!.firstName }]}
        rightContent={<CompanyLogoComponent />}
      />
      <Dashboard />
    </div>
  );
};
