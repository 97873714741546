import { Button, Card } from 'flowbite-react';
import { useState } from 'react';

import { FinoLink } from '../../components/links/FinoLink';
import { FinologyTable } from '../../components/table/FinologyTable';
import { useGetRecentClientsQuery } from '../../graphql/generated';
import { UploadNsldsFileModal } from '../../modals/UploadNsldsFileModal';
import useFinologyTranslation from '../../translations/useFinologyTranslation';
import { toDollars } from '../../util/currency.formatter';

export const RecentClients = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, loading } = useGetRecentClientsQuery();
  const { t } = useFinologyTranslation();

  const clients = data?.clients;

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'fullName',
      key: 'name',
      render: (text: string, record: any) => (
        <FinoLink
          label={text}
          to={`${location.pathname}/clients/${record.id}`}
          className="max-w-[250px] text-sm"
        />
      ),
    },
    {
      title: 'FEDERAL',
      dataIndex: 'federalLoansSum',
      key: 'federal',
      render: (value: number) => <span className="text-sm text-gray-700">{toDollars(value)}</span>,
    },
    {
      title: 'PRIVATE',
      dataIndex: 'federalLoansSum',
      key: 'private',
      render: (value: number) => <span className="text-sm text-gray-700">{toDollars(value)}</span>,
    },
    {
      title: 'CREDIT CARD',
      dataIndex: 'creditCardLoansSum',
      key: 'creditCard',
      render: (value: number) => <span className="text-sm text-gray-700">{toDollars(value)}</span>,
    },
  ];

  const emptyText = () => (
    <div className="flex flex-col gap-2 justify-center items-center w-full mx-auto py-20 text-gray-500">
      <div className="font-bold">Clients not added yet</div>
      <div className="font-medium">
        This table will be automatically populated after you add a client
      </div>
      <Button className="mt-4" color="secondaryLight" onClick={() => setIsModalOpen(true)}>
        Add Client
      </Button>
    </div>
  );

  return (
    <div className="pt-6">
      <div className="mt-4 grid gap-4 grid-cols-2">
        <Card
          className="grow min-w-min"
          theme={{
            root: {
              children: 'p-0',
            },
          }}
        >
          <div className="px-6 py-4 flex justify-between w-full">
            <div className="text-lg font-semibold">Recent {t('borrowers.uppercase')}</div>
            {!!clients?.items?.length && (
              <div>
                <FinoLink
                  className="text-sm text-primary-900"
                  label="Search Clients"
                  to={`/clients`}
                />
              </div>
            )}
          </div>
          <FinologyTable
            type="no-promise"
            rows={clients?.items || []}
            columns={columns}
            emptyText={emptyText}
          />
        </Card>
      </div>
      <UploadNsldsFileModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};
