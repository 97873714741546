import { Button } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AccountType } from '../auth';
import { PageHeader } from '../components/PageHeader';
import { useGlobalState } from '../context/AppContext';
import { MyClients } from '../features/clients/MyClients';
import { useGetClientAdvisorApplicationsCountLazyQuery } from '../graphql/generated';
import { UploadNsldsFileModal } from '../modals/UploadNsldsFileModal';
import useFinologyTranslation from '../translations/useFinologyTranslation';

export const Clients = () => {
  const navigate = useNavigate();
  const { t } = useFinologyTranslation();
  const { user } = useGlobalState();
  const [getApplicationsCount, query] = useGetClientAdvisorApplicationsCountLazyQuery();
  const [uploadNsldsFileModalIsOpen, setUploadNsldsFileModalIsOpen] = useState(false);

  const isCollege = user?.accountType == AccountType.College;

  useEffect(() => {
    if (isCollege) return;

    async function loadProspectsCount() {
      getApplicationsCount();
    }

    loadProspectsCount();
  }, []);

  return (
    <>
      <UploadNsldsFileModal
        isOpen={uploadNsldsFileModalIsOpen}
        onClose={() => setUploadNsldsFileModalIsOpen(false)}
      />

      <PageHeader
        rightContent={
          <Button onClick={() => setUploadNsldsFileModalIsOpen(true)}>Add Client</Button>
        }
        breadcrumbs={[{ to: '/clients', text: `My ${t('borrowers.uppercase')}` }]}
      />
      <MyClients />
    </>
  );
};
