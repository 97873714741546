import dayjs from 'dayjs';
import { Button, Tooltip, Modal } from 'flowbite-react';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';

import { ClientAdvisorApplicationStatusLabel } from '../../components/labels/ClientAdvisorApplicationStatusLabel';
import { FinologyTable } from '../../components/table/FinologyTable';
import {
  ClientAdvisorApplicationStatus,
  ClientFindAnAdvisorApplicationView,
  ClientListViewSortInput,
  InputMaybe,
  useChangeClientAdvisorApplicationStatusMutation,
  useGetClientAdvisorApplications_BorrowerLazyQuery,
} from '../../graphql/generated';
import { notification } from '../../util/notification.utils';
import { getInitials } from '../../utils';

import type { ColumnsType } from '../../components/table/FinologyTable';

const WithdrawApplicationModal = ({
  applicationToDelete: applicationToWithdraw,
  closeModal,
}: {
  applicationToDelete?: ClientFindAnAdvisorApplicationView;
  closeModal: () => void;
}) => {
  const [changeStatus] = useChangeClientAdvisorApplicationStatusMutation();
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);

  return (
    <Modal show={applicationToWithdraw !== undefined} onClose={closeModal}>
      <Modal.Header>
        {`Withdraw application for advisor ${applicationToWithdraw?.advisorFullName}?`}
      </Modal.Header>
      <Modal.Body>
        <p>
          {applicationToWithdraw?.advisorFullName} will no longer be able to accept your
          application.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button color="light" onClick={closeModal}>
          Cancel{' '}
        </Button>
        <Button
          disabled={disableDeleteButton}
          onClick={async () => {
            setDisableDeleteButton(true);

            await changeStatus({
              variables: {
                input: {
                  applicationId: applicationToWithdraw!.id,
                  targetStatus: ClientAdvisorApplicationStatus.Withdrawn,
                },
              },
              refetchQueries: ['GetClientAdvisorApplications_Borrower'],
            });

            setDisableDeleteButton(false);

            closeModal();

            notification.success({
              message: `Application for ${applicationToWithdraw?.advisorFullName} was withdrawn.`,
              placement: 'bottom-center',
            });
          }}
        >
          Yes{' '}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const AdvisorApplicationsList = () => {
  const [getApplications, query] = useGetClientAdvisorApplications_BorrowerLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const [applicationToWithdraw, setApplicationToWithdraw] =
    useState<ClientFindAnAdvisorApplicationView>();

  const columns: ColumnsType<ClientFindAnAdvisorApplicationView> = [
    {
      title: 'NAME',
      dataIndex: 'advisorFullName',
      key: 'advisorFullName',
      render: (text, record) => (
        <div className="flex items-center justify-start">
          <div className="rounded-full font-bold bg-fino-blue-light w-10 h-10 flex items-center justify-center">
            {getInitials(text)}
          </div>
          <div className="ml-4">{text}</div>
        </div>
      ),
    },
    {
      title: 'SUBMITTED ON',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => (text ? dayjs(text).format('YYYY/MM/DD HH:mm') : null),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        <ClientAdvisorApplicationStatusLabel status={text as ClientAdvisorApplicationStatus} />
      ),
    },
    {
      title: '',
      key: 'action',
      className: 'w-32',
      render: (_, record) => {
        if (record.status !== ClientAdvisorApplicationStatus.Pending) return null;

        return (
          <div className="flex gap-2">
            <Tooltip content="Withdraw Application">
              <MdClose
                size={24}
                className="hover:cursor-pointer hover:text-primary"
                onClick={() => setApplicationToWithdraw(record)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  async function load(
    skip?: number,
    take?: number,
    filterValue?: string,
    sortBy?: InputMaybe<ClientListViewSortInput | ClientListViewSortInput[]>
  ) {
    const { data } = await getApplications({
      variables: {
        filter: filterValue ? { advisorFullName: { contains: filterValue } } : undefined,
        skip,
        take,
        sortBy,
      },
    });

    return {
      rows: data!.clientAdvisorApplications!.items as ClientFindAnAdvisorApplicationView[],
      totalCount: data!.clientAdvisorApplications!.totalCount,
    };
  }

  return (
    <>
      <WithdrawApplicationModal
        applicationToDelete={applicationToWithdraw}
        closeModal={() => setApplicationToWithdraw(undefined)}
      />
      <div className="flex">
        <div className="grow">
          <FinologyTable
            emptyText={() => {
              return (
                <div className="flex text-fino-grey">You do not have any advisor applications.</div>
              );
            }}
            searchVisible={false}
            columns={columns}
            dataSourcePromise={load}
            reloadDependency={query.previousData}
          />
        </div>
      </div>
    </>
  );
};
