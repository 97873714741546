import { Checkbox, Label, Radio } from 'flowbite-react';
import { Field } from 'formik';

// Radio component
const AntRadio = ({ field, form, ...props }: any) => {
  const shouldBeChecked = field.value.toString() == props.value.toString();
  return (
    <div className="flex gap-x-2 items-center">
      <Radio
        id={`${field.name}-${props.value}`}
        className={`${props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'} `}
        checked={shouldBeChecked}
        disabled={props.disabled}
        value={props.value}
        onChange={(e) => {
          form.setFieldValue(field.name, e.target.value);
          props.onChange?.(e);
        }}
      />
      <Label
        className={props.disabled ? `cursor-not-allowed` : 'cursor-pointer'}
        htmlFor={`${field.name}-${props.value}`}
      >
        {props.label}
      </Label>
    </div>
  );
};

// Checkbox component
const AntCheckbox = ({ field, form, ...props }: any) => {
  return (
    <div className="flex gap-x-2 items-center">
      {props.isLabelFirst && (
        <Label
          className={props.disabled ? `cursor-not-allowed` : 'cursor-pointer'}
          htmlFor={field.name}
        >
          {props.label}
        </Label>
      )}
      <Checkbox
        id={field.name}
        className={props.disabled ? `cursor-not-allowed` : 'cursor-pointer'}
        checked={field.value}
        disabled={props.disabled}
        onChange={(e) => {
          form.setFieldValue(field.name, e.target.checked);
          props.onChange?.(e);
        }}
      />
      {!props.isLabelFirst && (
        <Label
          className={props.disabled ? `cursor-not-allowed` : 'cursor-pointer'}
          htmlFor={field.name}
        >
          {props.label}
        </Label>
      )}
    </div>
  );
};

// Radio Group component
export const RadioGroupField = ({
  name,
  options,
  className,
  disabled,
  onChange,
  label,
}: {
  name: string;
  options: { value: boolean; label: string }[];
  className?: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  label?: string;
}) => {
  return (
    <div className={className}>
      {label && <label className="block mb-2">{label}</label>}
      <div className="flex flex-col gap-y-2">
        {options.map((option) => (
          <Field
            key={option.value}
            name={name}
            component={AntRadio}
            value={option.value}
            label={option.label}
            disabled={disabled}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
};

// Checkbox component export
export const CheckboxField = ({
  name,
  label,
  className,
  disabled,
  isLabelFirst,
  onChange,
}: {
  name: string;
  label: string;
  className?: string;
  disabled?: boolean;
  isLabelFirst?: boolean;
  onChange?: (e: any) => void;
}) => {
  return (
    <div className={className}>
      <Field
        name={name}
        label={label}
        component={AntCheckbox}
        disabled={disabled}
        isLabelFirst={isLabelFirst}
        onChange={onChange}
      />
    </div>
  );
};
