import dayjs from 'dayjs';

import { FinologyTable } from '../../../components/table/FinologyTable';
import {
  SuperAdminClientView,
  useSuperAdmin_GetClientsLazyQuery,
} from '../../../graphql/generated';

import type { ColumnsType } from '../../../components/table/FinologyTable';

const columns: ColumnsType<SuperAdminClientView> = [
  {
    title: '#',
    key: 'rowNum',
    render: (text, record, index) => {
      return index + 1;
    },
  },
  {
    title: 'FIRST NAME',
    dataIndex: 'firstName',
    key: 'firstName',
    render: (text, record) => text,
  },
  {
    title: 'LAST NAME',
    dataIndex: 'lastName',
    key: 'lastName',
    render: (text, record) => text,
  },
  {
    title: 'EMAIL',
    dataIndex: 'email',
    key: 'email',
    render: (text, record) => text,
  },
  {
    title: 'REGISTRATION DATE',
    dataIndex: 'registrationDate',
    key: 'registrationDate',
    render: (text, record) => (text ? dayjs(text).format('YYYY/MM/DD HH:mm') : null),
  },
  // {
  //   title: 'Last Login Date',
  //   dataIndex: 'lastLoginDate',
  //   key: 'lastLoginDate',
  //   render: (text, record) => (text ? dayjs(text).format('YYYY/MM/DD HH:mm') : null),
  // },
  {
    title: 'ADVISOR',
    dataIndex: 'advisorName',
    key: 'advisorName',
    render: (text, record) => text,
  },
  {
    title: 'REF ID',
    dataIndex: 'signupRefId',
    key: 'signupRefId',
    render: (text, record) => text,
  },
];

export const SuperAdminClientsList = () => {
  const [getClients, query] = useSuperAdmin_GetClientsLazyQuery();

  async function load(skip?: number, take?: number, filterValue?: string) {
    const { data } = await getClients({
      variables: {
        filter: filterValue
          ? {
              or: [
                { firstName: { contains: filterValue } },
                { lastName: { contains: filterValue } },
                { email: { contains: filterValue } },
              ],
            }
          : undefined,
        skip: skip,
        take: take,
      },
    });

    return {
      rows: data!.superAdmin_Clients!.items as SuperAdminClientView[],
      totalCount: data!.superAdmin_Clients!.totalCount,
    };
  }

  return (
    <>
      <div className="flex">
        <div className="grow">
          <FinologyTable
            defaultPageSize={50}
            searchVisible={true}
            columns={columns}
            dataSourcePromise={load}
            reloadDependency={query.previousData}
            searchPlaceholder="Search by first name, last name or email..."
          />
        </div>
      </div>
    </>
  );
};
