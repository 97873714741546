import numeral from 'numeral';

const formatter = (
  val: number | null | undefined,
  formatString: string
): string | null | undefined => {
  if (val === null || val === undefined) return val;

  return numeral(val).format(formatString);
};

export const toDollars = (value: number | null | undefined) => {
  return formatter(value || 0, '$0,0');
};

export const toDollarsWithDecimals = (value: number | null | undefined) => {
  return formatter(value || 0, '$0,0.00');
};

export const toShorthand = (value: number | null | undefined) => {
  if (value === null || value === undefined) return value;

  if (value >= 1000000) {
    // Convert to M format with one decimal place
    return formatter(value / 1000000, '0,0.0') + 'M';
  }

  if (value >= 1000) {
    // Convert to k format with one decimal place
    return formatter(value / 1000, '0,0.0') + 'k';
  }

  return formatter(value, '0,0');
};
