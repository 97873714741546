import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { FullNameLabel } from '../../components/labels/FullNameLabel';
import { useGetClientsForSelectorLazyQuery } from '../../graphql/generated';
import useDebouncedState from '../../hooks/useDebouce';

import SelectField from './SelectField';

export const ClientSelectorField = ({
  name,
  placeholder,
  selectPlaceholder,
  className,
  idToExclude,
  onValueChanged,
}: {
  name: string;
  placeholder?: string;
  selectPlaceholder?: string;
  className?: string;
  idToExclude?: number | null;
  onValueChanged?: (selectedId: string | null) => Promise<void>;
}) => {
  const { setFieldValue } = useFormikContext<any>();
  const [getClients, { loading }] = useGetClientsForSelectorLazyQuery();
  const [hasMore, setHasMore] = useState(true);
  const [filterValue, debouncedFilterValue, setFilterValue] = useDebouncedState<string | undefined>(
    undefined,
    250
  );

  const [uiClients, setUiClients] = useState<{ firstName: string; lastName: string; id: number }[]>(
    []
  );
  const [skip, setSkip] = useState(0);
  const take = 50;

  const loadClients = async (skip: number, take: number, filterValue?: string) => {
    const { data } = await getClients({
      variables: {
        filter: filterValue ? { fullName: { contains: filterValue } } : undefined,
        skip,
        take,
      },
    });

    if (data?.clients && data?.clients?.items && data?.clients?.items?.length > 0) {
      const newClients = [
        ...uiClients,
        ...data!.clients!.items!.filter((x) => x.id !== idToExclude),
      ];

      const seenIds = new Set();
      const clientsToAdd = newClients.filter((item) => {
        if (seenIds.has(item.id)) {
          return false;
        }
        seenIds.add(item.id);
        return true;
      });

      setUiClients([...clientsToAdd]);

      if (data.clients.items.length < take) {
        setHasMore(false);
      } else {
        setSkip(clientsToAdd.length);
        setHasMore(true);
      }
    } else {
      setSkip(0);
      setHasMore(false);
    }
  };

  useEffect(() => {
    loadClients(0, take);
  }, []);

  useEffect(() => {
    if (debouncedFilterValue && debouncedFilterValue != filterValue) {
      setUiClients([]);
      loadClients(skip, take, debouncedFilterValue);
    }
  }, [debouncedFilterValue]);

  const options = uiClients.map((d) => (
    <option value={d.id} key={d.id.toString()}>
      {FullNameLabel({
        firstName: d.firstName,
        lastName: d.lastName,
      })}
    </option>
  ));

  return (
    <div className={className}>
      <SelectField
        onInputChange={(e) => {
          if (e != undefined) {
            setFilterValue(e);
            setUiClients([]);
            loadClients(0, take, e);
          }
        }}
        onChange={(e) => {
          setFieldValue(name, e);
        }}
        name={name}
        label={placeholder}
        onLoadMore={() => loadClients(skip, take)}
        hasMore={hasMore}
        loading={loading}
      >
        {options}
      </SelectField>
    </div>
  );
};
