import { Button, Modal, Tooltip } from 'flowbite-react';
import { useMemo, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { MdEdit, MdDelete } from 'react-icons/md';

import FinoCard from '../../../../components/FinoCard/FinoCard';
import { FlyoutLink } from '../../../../components/links/FlyoutLink';
import { FinologyTable } from '../../../../components/table/FinologyTable';
import {
  InputMaybe,
  PrivateLoanView,
  PrivateLoanViewSortInput,
  SortEnumType,
  useDeletePrivateLoanMutation,
  useGetPrivateLoansLazyQuery,
} from '../../../../graphql/generated';
import { useFlyoutNavigate } from '../../../../hooks/useFlyoutNavigate';
import { useTableSortBy } from '../../../../hooks/useTableSortBy';
import { toDollars } from '../../../../util/currency.formatter';
import { notification } from '../../../../util/notification.utils';
import { toPercentage } from '../../../../util/number.formatter';

import type { ColumnsType } from '../../../../components/table/FinologyTable';

const DeletePrivateLoanModal = ({
  loanToDelete,
  closeModal,
}: {
  loanToDelete?: PrivateLoanView;
  closeModal: () => void;
}) => {
  const [deleteLoan] = useDeletePrivateLoanMutation();
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);

  return (
    <Modal show={loanToDelete !== undefined} onClose={closeModal}>
      <Modal.Header>Delete Private Loan</Modal.Header>
      <Modal.Body>
        <p>Proceed with deleting of {loanToDelete?.loanName}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button color="light" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={disableDeleteButton}
          onClick={async () => {
            setDisableDeleteButton(true);

            await deleteLoan({
              variables: {
                id: loanToDelete!.id,
              },
              refetchQueries: ['GetPrivateLoans', 'GetClientDashboard', 'GetClientChecklist'],
            });

            setDisableDeleteButton(false);

            closeModal();

            notification.success({
              message: `${loanToDelete?.loanName} was successfully deleted`,
              placement: 'bottom-center',
            });
          }}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const PrivateLoansGrid = ({ clientId }: { clientId: number }) => {
  const { navigate } = useFlyoutNavigate();
  const [privateLoanToDelete, setPrivateLoanToDelete] = useState<PrivateLoanView>();
  const { sortBy, prepareSortColumns, triggerRefetch } = useTableSortBy<PrivateLoanViewSortInput>({
    loanName: SortEnumType.Asc,
  });

  const [getPrivateLoans, getPrivateLoansQuery] = useGetPrivateLoansLazyQuery();

  const privateLoansColumns: ColumnsType<PrivateLoanView> = [
    {
      title: 'LOAN NAME',
      dataIndex: 'loanName',
      key: 'loanName',
      render: (text, record) => (
        <FlyoutLink flyoutId="upsert-private-loan" params={`loanId=${record.id}`}>
          {text}
        </FlyoutLink>
      ),
    },
    {
      title: 'LENDER',
      dataIndex: 'lender',
      key: 'lender',
      render: (text) => text || '-',
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => toDollars(text) || '-',
      ...prepareSortColumns('amount', 'AMOUNT', triggerRefetch),
    },
    {
      dataIndex: 'interestRate',
      key: 'interestRate',
      render: (text) => toPercentage(text) || '-',
      ...prepareSortColumns('interestRate', 'RATE', triggerRefetch),
    },
    {
      dataIndex: 'monthlyPayment',
      key: 'monthlyPayment',
      ...prepareSortColumns('monthlyPayment', 'MIN. MONTHLY PAYMENT', triggerRefetch),
      render: (text) => toDollars(text),
    },
    {
      title: '',
      key: 'action',
      clasName: 'w-20',
      render: (_, record) => (
        <div className="flex gap-2">
          <Tooltip content="Edit">
            <MdEdit
              size={24}
              className="hover:cursor-pointer hover:text-primary"
              onClick={() =>
                navigate('upsert-private-loan', `loanId=${record.id}&clientId=${clientId}`)
              }
            />
          </Tooltip>
          <Tooltip content="Delete">
            <MdDelete
              size={24}
              className="hover:cursor-pointer hover:text-primary"
              onClick={() => setPrivateLoanToDelete(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  async function load(
    skip?: number,
    take?: number,
    filterValue?: string,
    sortBy?: InputMaybe<PrivateLoanViewSortInput | PrivateLoanViewSortInput[]>
  ) {
    const { data } = await getPrivateLoans({
      variables: {
        clientId: clientId,
        skip,
        take,
        sortBy,
      },
    });

    return {
      rows: data!.privateLoans!.items as PrivateLoanView[],
      totalCount: data!.privateLoans!.totalCount,
    };
  }

  const EmptyState = () => {
    const { navigate } = useFlyoutNavigate();

    return (
      <div className="flex flex-col items-center justify-center py-16 px-4">
        <div className="text-gray-400 mb-4">
          <FaUser className="text-gray-400" size={40} />
        </div>
        <h3 className="text-xl font-semibold text-gray-500 mb-2">No Private Loans Added Yet</h3>
        <p className="text-gray-500 text-center mb-6">
          This table will be automatically populated after you add a private loan.
        </p>
        <div className="flex gap-3">
          <Button
            color="secondaryLight"
            onClick={() => navigate('upsert-private-loan', `clientId=${clientId}`)}
          >
            Add Private Loan
          </Button>
        </div>
      </div>
    );
  };

  const { totalAmount, weightedAverageRate, sumOfPayments } = useMemo(() => {
    let totalAmount = 0;
    let sumOfPayments = 0;
    const weightedFactorTotal =
      getPrivateLoansQuery.data?.privateLoans?.items?.reduce((acc, item) => {
        return acc + (item.amount || 0) * (item.interestRate || 0);
      }, 0) || 0;

    getPrivateLoansQuery.data?.privateLoans?.items?.forEach((item) => {
      totalAmount += item.amount;
      sumOfPayments += item.monthlyPayment;
    });
    const weightedAverageRate = weightedFactorTotal / totalAmount;

    return { totalAmount, weightedAverageRate, sumOfPayments };
  }, [getPrivateLoansQuery.data?.privateLoans?.items]);

  return (
    <>
      <DeletePrivateLoanModal
        loanToDelete={privateLoanToDelete}
        closeModal={() => setPrivateLoanToDelete(undefined)}
      />
      <div
        className={`grid grid-cols-3 gap-4 mb-8 ${
          !getPrivateLoansQuery.data?.privateLoans?.totalCount ? 'opacity-50' : ''
        }`}
      >
        <FinoCard title="Total Private Loan Amount">{toDollars(totalAmount)}</FinoCard>
        <FinoCard title="Weighted Average Rate">{toPercentage(weightedAverageRate)}</FinoCard>
        <FinoCard title="Sum of Payments">{toDollars(sumOfPayments)}</FinoCard>
      </div>

      <div className="flex-1 gap-y-2">
        <FinologyTable
          emptyText={() => <EmptyState />}
          columns={privateLoansColumns}
          dataSourcePromise={load}
          reloadDependency={getPrivateLoansQuery.previousData}
          searchPlaceholder="Search for private loans..."
          sortBy={sortBy}
          hideFooter={true}
          tableHeadingLabel="Private Loans"
        />
      </div>
    </>
  );
};

export const PrivateLoans = ({ clientId }: { clientId: number }) => {
  return (
    <div className="py-8">
      <PrivateLoansGrid clientId={clientId} />
    </div>
  );
};
