import classNames from 'classnames';
import { Label, Spinner } from 'flowbite-react';
import { useFormikContext } from 'formik';
import { createRef, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { GoXCircleFill } from 'react-icons/go';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { RiFileFill } from 'react-icons/ri';
import { TbFileImport } from 'react-icons/tb';

import { CreditCardLoanDtoInput } from '../../../../graphql/generated';

interface Props {
  name: string;
  onUpload: (response: CreditCardLoanDtoInput[]) => void;
}

export const UploadField = ({ name, onUpload }: Props) => {
  const context = useFormikContext<any>();
  const dropRef = createRef<HTMLDivElement>();
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadingError, setUploadingError] = useState<string | null>(null);

  const error =
    (!!context.touched[name] || context.submitCount) && !!context.errors[name] && !uploading;

  const uploadFiles = async (file: File) => {
    const formData = new FormData();
    formData.append('ccReport', file);
    setUploading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_INTERNAL_API_HOST}/CreditCardReport/Extract`,
        {
          method: 'POST',
          body: formData,
          credentials: 'include',
          headers: {
            'x-csrf': '1',
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        onUpload(result);
        setUploadingError(null);
        context.setFieldValue(name, result);
      } else {
        setUploadingError('Upload failed');
        context.setErrors({ [name]: `Upload failed.` });
      }
    } catch (error: any) {
      setUploadingError(error.message);
      context.setErrors({ [name]: error.message });
    } finally {
      setUploading(false);
    }
  };

  const handleFileSelected = async (files: FileList | null) => {
    context.setFieldTouched(name);
    if (!files) return;

    setFileName(files[0].name);
    await uploadFiles(files[0]);
  };

  const UploadSection = () => {
    return (
      <div className="flex flex-col gap-y-2 justify-center items-center w-full">
        <div>
          <TbFileImport className="text-primary-800" size={40} />
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="text-primary-800">Upload from computer</div>
          <div className="text-gray-600 text-xs">drag &amp; drop or click to upload the file</div>
        </div>
        <input
          type="file"
          accept=".pdf"
          hidden
          onChange={async (e) => {
            await handleFileSelected(e.target.files);
          }}
        />
      </div>
    );
  };

  const FileNameSection = () => {
    return (
      <div className={`border border-gray-200 bg-gray-100 w-full ${!fileName ? 'p-4' : 'p-1'}`}>
        <div className="flex flex-row justify-between items-center px-2 py-1">
          <div className="flex flex-row gap-x-2 items-center">
            {fileName ? <RiFileFill size={32} fill="gray" /> : <div className="h-8"></div>}
            <div className="flex flex-col">
              <div className={uploadingError ? 'text-red-800' : ''}>{fileName}</div>
              <div>
                {fileName && (
                  <button
                    className="text-xs"
                    type="button"
                    onClick={() => {
                      setFileName(null);
                      context.setFieldValue(name, undefined);
                    }}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          </div>
          <div>
            {fileName &&
              (uploadingError ? (
                <GoXCircleFill className="text-red-600" size={24} />
              ) : (
                <FaCheckCircle className="text-green-500" size={24} />
              ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-y-3 w-full" ref={dropRef}>
      <Label className="text-gray-500">This file can be found at equifax.</Label>
      {!fileName ? (
        <div
          onClick={() => {
            const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
            if (fileInput) {
              fileInput.click();
            }
          }}
          className={classNames(
            'flex bg-primary-50 rounded-lg border-2 border-primary-200 py-7 px-6 items-center',
            {
              'border-red': error && !isDragging,
              'border-gray-200': !error && !isDragging,
              'border-gray-600': isDragging,
            }
          )}
        >
          <UploadSection />
        </div>
      ) : (
        <FileNameSection />
      )}

      {uploading ? (
        <div className="flex items-center gap-x-2">
          <Spinner size={'sm'} />
          <span>Processing file...</span>
        </div>
      ) : uploadingError ? (
        <div className="flex items-center gap-x-2 text-red-500">
          <HiOutlineExclamationCircle />
          <span>
            File processing failed <br />
            Please check your file or try using another one
          </span>
        </div>
      ) : null}
    </div>
  );
};
