import dayjs from 'dayjs';
import { Accordion, Alert, Button, Spinner } from 'flowbite-react';
import { Form, Formik, FormikErrors } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { PanelHeader } from '../../../components/Accordion/PanelHeader';
import { ButtonWithTooltip } from '../../../components/Buttons/ButtonWithTooltip';
import { HighlightedCard } from '../../../components/cards/Cards';
import { Flyout, useFlyoutContext } from '../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../components/flyout/FlyoutHeader';
import { FinologyTable } from '../../../components/table/FinologyTable';
import {
  ForgivenessType,
  useGetClientDetailsLazyQuery,
  useGetClientReportLazyQuery,
  useGetCompanyInformationLazyQuery,
  useGetUserLazyQuery,
  useSaveDraftReportMutation,
} from '../../../graphql/generated';
import { useApiClient } from '../../../hooks/useApiClient';
import { toDollars } from '../../../util/currency.formatter';
import { toPercentage } from '../../../util/number.formatter';
import { chunkArray } from '../../calculator/results/results.helpers';
import { DateField } from '../../formComponents/DateField';
import { TextInputField } from '../../formComponents/InputField';
import { TextAreaField } from '../../formComponents/TextAreaField';

const getMontlyPayments = (simulationResults: any, repaymentPlans: any[]) => {
  const formulaResults = simulationResults?.paymentOptions?.federal?.fr;
  const rows: any[] = [];
  const timelineStartYear = dayjs(
    simulationResults?.paymentOptions?.federal?.timelineStartDate
  ).year();

  let maxYear = 0;

  const monthOffset = simulationResults?.paymentOptions?.federal?.resultIndexForRepaymentStart % 12;

  formulaResults.forEach((monthlyResultInYear: any, i: number) => {
    if (i % 12 === monthOffset) {
      let totalMonthly = 0;

      repaymentPlans.forEach((repaymentPlan) => {
        const formulaResultInYear = monthlyResultInYear.filter(
          (x: any) => x.key === repaymentPlan
        )[0];

        if (formulaResultInYear) {
          totalMonthly += formulaResultInYear.value.mp + formulaResultInYear.value.ep;
        }
      });

      rows.push({
        year: Math.ceil(timelineStartYear + maxYear - 1),
        payment: totalMonthly,
      });

      maxYear += 1;
    }
  });

  const shouldAddLastElement = formulaResults.length % 12 !== monthOffset;
  if (shouldAddLastElement) {
    rows.push({
      year: Math.ceil(timelineStartYear + maxYear),
      payment: 0,
    });
  }

  return rows;
};

const formulaTypeMap: any = {
  0: 'PAYE',
  1: 'REPAYE',
  2: 'IBR',
  3: 'ICR',
  4: 'STANDARD',
  5: 'EXTENDED',
  6: 'GRADUATED',
  7: 'SAVE',
};

const objectsWithForgivenessType = (array: any, keyToCheck: ForgivenessType) => {
  return array.filter((obj: any) => obj.forgivenessType === keyToCheck);
};

const calculateProFormaTaxedAmount = (simulationResult: any, calculatorInput: any) => {
  const finalLoansIteration = simulationResult?.finalIterationDate
    ? dayjs(simulationResult?.finalIterationDate)
    : undefined;

  const federalYearsToRun =
    finalLoansIteration && calculatorInput.repaymentStartDate
      ? finalLoansIteration.year() - calculatorInput.repaymentStartDate.$y
      : 0;

  const estimatedYearlySavingTowardsTaxBomb =
    (calculatorInput.estimatedMonthlySavingTowardsTaxBomb || 0) * 12;
  const rateOfReturn = (calculatorInput.rateOfReturnOnTaxSavingsBomb || 0) / 100;

  let proFormaAssetValue = calculatorInput.currentSavingTowardsTaxBomb || 0;

  for (let i = 1; i <= federalYearsToRun; i++) {
    proFormaAssetValue += estimatedYearlySavingTowardsTaxBomb;
    proFormaAssetValue *= 1 + rateOfReturn;
  }

  return proFormaAssetValue;
};

type FormValues = Partial<any>;

const FlyoutContent = () => {
  const [lazyCompanyDetailsQuery] = useGetCompanyInformationLazyQuery();
  const [initialValues, setInitialValues] = useState<FormValues>({});
  const [lazyUserDetailsQuery] = useGetUserLazyQuery();
  const navigate = useNavigate();
  const submitType = useRef<'pdf' | 'save'>('pdf');

  const location = useLocation();
  const { calculatorInput, simulationResults, initialData } = location.state;

  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const clientId = params.get('clientId');
  const parsedClientId = clientId ? Number.parseInt(clientId) : null;

  const reportId = params.get('reportId');

  const parsedReportId = reportId ? Number.parseInt(reportId) : null;

  const [lazyClientQuery] = useGetClientDetailsLazyQuery();
  const [lazyReport] = useGetClientReportLazyQuery();

  const [saveDraftReport] = useSaveDraftReportMutation();

  const [staticValues, setStaticValues] = useState<any>();

  const { closeTopFlyout } = useFlyoutContext();

  let rows: any[] = [];

  useEffect(() => {
    if (!parsedClientId) return;
    if (!location) return;

    const load = async () => {
      const { data } = await lazyClientQuery({ variables: { clientId: parsedClientId } });
      const { data: companyData } = await lazyCompanyDetailsQuery();
      const { data: userData } = await lazyUserDetailsQuery();

      const { client } = data!;

      if (parsedReportId) {
        const { data } = await lazyReport({
          variables: {
            reportId: parsedReportId,
          },
        });

        const report = data?.clientReports?.items![0];

        setStaticValues({
          rows: rows,
          paidOffYear: report!.paidOffYear,
          annualIncomeGrowth: report!.annualIncomeGrowth,
          yearsInRepayment: report!.yearsInRepayment,
          adjustedGrossIncome: report!.adjustedGrossIncome,
          sumOfFuturePayment: report!.sumOfFuturePayment,
          amountForgiven: report!.totalProjectedForgiveness,
          currentMonthlyPayment: report!.currentMonthlyPayment,
          weightedAverageInterestRate: report!.weightedAverageInterestRate,
          currentBalance: report!.currentBalance,
          filteredMonthlyPayments: report!.payments.map((p) => {
            return {
              year: p.year,
              payment: p.payment,
            };
          }),
          netDifference: report!.netDifference,
          isTimeBasedForgiveness: !!report!.isTimeBasedForgiveness,
          proFormaTaxedAmount: report!.proFormaTaxedAmount,
          proFormaAssetValue: report!.proFormaAssetValue,
        });

        setInitialValues({
          clientName: `${client?.firstName} ${client?.lastName}`,
          logoUrl: companyData?.tenant.logoUrl,
          disclaimer: companyData?.tenant.reportDisclaimer,
          advisorName: `${userData?.user?.firstName} ${userData?.user?.lastName}`,
          companyName: companyData?.tenant.name,
          reportDate: dayjs(report?.date),
          reportName: report?.name,
          repaymentPlans: report!.plans.map((p) => {
            return {
              repaymentPlanType: p.repaymentPlanType,
              description: p.description,
            };
          }),
          actionItem1: report?.actionItem1,
          actionItem2: report?.actionItem2,
          actionItem3: report?.actionItem3,
          actionItem4: report?.actionItem4,
          actionItem5: report?.actionItem5,
          actionItem6: report?.actionItem6,
          suggestions: report?.suggestions,
          notes: report?.notes,
          title: userData?.user?.title,
        });
      } else {
        const forgivenessResults: any[] = simulationResults?.paymentOptions?.federal?.fgr;

        const { isTimeBasedForgivnessActive } = calculatorInput;

        const repaymentPlans =
          simulationResults?.paymentOptions?.federal?.formulaWithLoanCounts.map((x: any) => ({
            repaymentPlanType: formulaTypeMap[x.formulaType],
            description: '',
          }));

        rows = rows.reduce((a, b) => a.concat(b), []);

        const proFormaTaxedAmount = rows.reduce((prev, next) => prev + next.pfta, 0);
        const proFormaAssetValue = calculateProFormaTaxedAmount(
          simulationResults?.paymentOptions?.federal,
          calculatorInput
        );
        const netDifference = proFormaTaxedAmount - proFormaAssetValue;

        const monthlyPayments = getMontlyPayments(
          simulationResults,
          simulationResults?.paymentOptions?.federal?.formulaWithLoanCounts.map(
            (x: any) => x.formulaType
          )
        );

        const filteredMonthlyPayments = monthlyPayments.filter(
          (x) => x.year >= new Date().getFullYear()
        );

        const currentBalance = simulationResults?.paymentOptions?.federal?.loansSum ?? 0;
        const weightedAverageInterestRate =
          simulationResults?.paymentOptions?.federal?.averageInterestRate ?? 0;
        const currentMonthlyPayment =
          simulationResults?.paymentOptions?.federal?.currentMonthlyPayment ?? 0;
        const amountForgiven = simulationResults.paymentOptions?.federal.amountForgiven ?? 0;
        const sumOfFuturePayment = simulationResults?.paymentOptions?.federal?.totalOutOfPocket;
        const adjustedGrossIncome = calculatorInput.clientAgiProjections[0] ?? 0;
        const yearsInRepayment =
          new Date(simulationResults?.paymentOptions?.federal?.finalIterationDate).getFullYear() -
          calculatorInput.repaymentStartDate.$y;
        const annualIncomeGrowth = calculatorInput.agiInflation / 100 ?? 0;

        const paidOffYear = new Date(
          simulationResults?.paymentOptions?.federal?.finalIterationDate
        ).getFullYear();

        if (isTimeBasedForgivnessActive) {
          rows = forgivenessResults.map((i) => {
            return objectsWithForgivenessType(i.f, ForgivenessType.TimeBased).map((t: any) => ({
              ...t,
              type: i.t,
            }));
          });
        } else {
          rows = forgivenessResults.map((i) => {
            return objectsWithForgivenessType(i.f, ForgivenessType.Pslf).map((t: any) => ({
              ...t,
              type: i.t,
            }));
          });
        }

        setStaticValues({
          rows: rows,
          paidOffYear: paidOffYear,
          annualIncomeGrowth: annualIncomeGrowth,
          yearsInRepayment: yearsInRepayment,
          adjustedGrossIncome: adjustedGrossIncome,
          sumOfFuturePayment: sumOfFuturePayment,
          amountForgiven: amountForgiven,
          currentMonthlyPayment: currentMonthlyPayment,
          weightedAverageInterestRate: weightedAverageInterestRate,
          currentBalance: currentBalance,
          filteredMonthlyPayments: filteredMonthlyPayments,
          netDifference: netDifference,
          isTimeBasedForgivness: isTimeBasedForgivnessActive,
          proFormaTaxedAmount: proFormaTaxedAmount,
          proFormaAssetValue: proFormaAssetValue,
        });

        setInitialValues({
          clientName: `${client?.firstName} ${client?.lastName}`,
          logoUrl: companyData?.tenant.logoUrl,
          advisorName: `${userData?.user?.firstName} ${userData?.user?.lastName}`,
          disclaimer: companyData?.tenant.reportDisclaimer,
          companyName: companyData?.tenant.name,
          reportDate: dayjs(new Date()),
          reportName: `Report ${new Date().toLocaleDateString()}`,
          repaymentPlans,
          actionItem1: '',
          actionItem2: '',
          actionItem3: '',
          actionItem4: '',
          actionItem5: '',
          actionItem6: '',
          suggestions: '',
          notes: '',
          title: userData?.user?.title,
        });
      }
    };

    load();
  }, [parsedClientId, parsedReportId, location]);

  if (!staticValues) return <Spinner size={'xl'} />;

  const chunks = chunkArray(
    staticValues.filteredMonthlyPayments,
    staticValues.filteredMonthlyPayments.length / 2
  );

  const api = useApiClient();

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validate={(values) => {
        const errors: FormikErrors<any> = {};

        if (!values.reportName) {
          errors.reportName = 'Report Name is required';
        }

        if (values.reportName && values.reportName.length > 50) {
          errors.reportName = 'Report Name is too long';
        }

        if (values.notes && values.notes.length > 1000) {
          errors.notes = 'Notes is too long';
        }

        if (values.suggestions && values.suggestions.length > 1000) {
          errors.suggestions = 'Suggestions is too long';
        }

        [
          values.actionItem1,
          values.actionItem2,
          values.actionItem3,
          values.actionItem4,
          values.actionItem5,
          values.actionItem6,
        ].forEach((actionItem, index) => {
          if (actionItem && actionItem.length > 200) {
            errors[`actionItem${index}`] = 'Action Item is too long';
          }
        });

        if (values.repaymentPlans) {
          values.repaymentPlans.forEach((repaymentPlan: any, index: number) => {
            if (repaymentPlan.description && repaymentPlan.description.length > 1000) {
              errors[`repaymentPlans[${index}].description`] = 'Description is too long';
            }
          });
        }

        return errors;
      }}
      onSubmit={async (v, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        const reportDate = v.reportDate ?? new Date();

        if (submitType.current == 'pdf') {
          const requestBody = {
            advisorTitle: v.title,
            advisorName: v.advisorName,
            logoUrl: v.logoUrl,
            clientName: v.clientName,
            companyName: v.companyName,
            suggestions: v.suggestions,
            repaymentPlans: v.repaymentPlans,
            notes: v.notes,
            disclaimer: v.disclaimer,
            actionItem1: v.actionItem1,
            actionItem2: v.actionItem2,
            actionItem3: v.actionItem3,
            actionItem4: v.actionItem4,
            actionItem5: v.actionItem5,
            actionItem6: v.actionItem6,
            payments: staticValues.filteredMonthlyPayments,
            currentPlanInfo: {
              currentBalance: staticValues.currentBalance,
              weightedAverageInterestRate: staticValues.weightedAverageInterestRate,
              currentMonthlyPayment: staticValues.currentMonthlyPayment,
            },
            forgivenessSummary: {
              totalProjectedForgiveness: staticValues.amountForgiven,
              proFormaTaxedAmount: staticValues.proFormaTaxedAmount,
              proFormaAssetValue: staticValues.proFormaAssetValue,
              netDifference: staticValues.netDifference,
            },
            sumOfFuturePayment: staticValues.sumOfFuturePayment,
            assumptions: {
              adjustedGrossIncome: staticValues.adjustedGrossIncome,
              yearsInRepayment: staticValues.yearsInRepayment,
              annualIncomeGrowth: staticValues.annualIncomeGrowth,
            },
            date: reportDate,
            paidOffYear: staticValues.paidOffYear,
          };

          const res = await api.post('RepaymentPdf/GeneratePdfForClient', requestBody, {
            responseType: 'arraybuffer',
          });

          const blob = new Blob([res.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          const anchor = window.document.createElement('a');
          anchor.download = 'dl.pdf';
          anchor.href = url;
          anchor.click();
        } else {
          await saveDraftReport({
            variables: {
              input: {
                id: parsedReportId,
                clientId: parsedClientId!,
                suggestions: v.suggestions,
                repaymentPlans: v.repaymentPlans,
                notes: v.notes,
                disclaimer: v.disclaimer,
                actionItem1: v.actionItem1,
                actionItem2: v.actionItem2,
                actionItem3: v.actionItem3,
                actionItem4: v.actionItem4,
                actionItem5: v.actionItem5,
                actionItem6: v.actionItem6,
                reportName: v.reportName,
                payments: staticValues.filteredMonthlyPayments,
                currentPlanInfo: {
                  currentBalance: staticValues.currentBalance,
                  weightedAverageInterestRate: staticValues.weightedAverageInterestRate,
                  currentMonthlyPayment: staticValues.currentMonthlyPayment,
                },
                forgivenessSummary: {
                  totalProjectedForgiveness: staticValues.amountForgiven,
                  proFormaTaxedAmount: staticValues.proFormaTaxedAmount,
                  proFormaAssetValue: staticValues.proFormaAssetValue,
                  netDifference: staticValues.netDifference,
                },
                sumOfFuturePayment: staticValues.sumOfFuturePayment,
                assumptions: {
                  adjustedGrossIncome: staticValues.adjustedGrossIncome,
                  yearsInRepayment: staticValues.yearsInRepayment,
                  annualIncomeGrowth: staticValues.annualIncomeGrowth,
                },
                date: reportDate,
                paidOffYear: staticValues.paidOffYear,
                isTimeBasedForgiveness: !!staticValues.isTimeBasedForgivness,
              },
            },
            refetchQueries: ['GetClientReports'],
          });

          if (reportId) {
            closeTopFlyout();
          } else {
            navigate(`?flyout=simulation-results`, {
              state: {
                calculatorInput: calculatorInput,
                initialData: initialData,
                simulationResults: simulationResults,
              },
            });
          }
        }
      }}
    >
      {({ isSubmitting, values, submitForm }) => {
        return (
          <>
            <FlyoutHeader
              primaryButton={{
                tooltip: 'Exporting does not save the report for later use.',
                form: 'create-report-form',
                children: 'Export Report',
                disabled: isSubmitting,
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                },
              }}
              secondaryButton={{
                tooltip: 'The "Save Report" button saves the report for later use.',
                onClick: async () => {
                  submitType.current = 'save';

                  await submitForm();
                },

                children: reportId ? 'Update Report' : 'Save Report',
              }}
              rightContent={
                <Button
                  color="light"
                  onClick={() => {
                    if (reportId) {
                      closeTopFlyout();
                    } else {
                      navigate(`?flyout=simulation-results`, {
                        state: {
                          calculatorInput: calculatorInput,
                          initialData: initialData,
                          simulationResults: simulationResults,
                        },
                      });
                    }
                  }}
                >
                  Cancel
                </Button>
              }
              label={`${reportId ? 'Edit' : 'Create'} Report`}
            />
            <Form id="create-report-form">
              <div className="flex mx-auto flex-col gap-6 ">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <Accordion>
                    <Accordion.Panel>
                      <Accordion.Title>
                        <PanelHeader>Report Overview</PanelHeader>
                      </Accordion.Title>
                      <Accordion.Content className="pb-8">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                          <div className="flex flex-col gap-1">
                            <span className="text-gray-500 ">Client Name</span>
                            <span>{values.clientName}</span>
                          </div>
                          <DateField
                            name="reportDate"
                            className="mt-1"
                            label="Date"
                            placeholder="12/08/2024"
                            size="small"
                            sizing="md"
                          />
                          <TextInputField
                            className="col-span-2"
                            name="reportName"
                            label="Report Name"
                            placeholder="Report 12/08/2024"
                          />
                          <div className="flex flex-col gap-1">
                            <span className="text-gray-500 ">Disclaimer</span>
                            <span>
                              {values.disclaimer ||
                                'Disclaimer can be set on the Manage Company page'}
                            </span>
                          </div>
                        </div>
                      </Accordion.Content>
                    </Accordion.Panel>
                  </Accordion>

                  <Accordion>
                    <Accordion.Panel>
                      <Accordion.Title>
                        <PanelHeader>Company Overview</PanelHeader>
                      </Accordion.Title>
                      <Accordion.Content className="pb-8">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                          <div className="flex flex-col gap-1">
                            <span className="text-gray-500 ">Company Name</span>
                            <span>{values.companyName || '-'}</span>
                          </div>
                          <div className="flex flex-col gap-1">
                            <span className="text-gray-500 ">Your First and Last Name</span>
                            <span>{values.advisorName}</span>
                          </div>
                          <div className="flex flex-col gap-1 col-span-2">
                            <div className="text-gray-500 ">Your Title</div>
                            <div>
                              {values.title || 'Title can be set on the Manage Profile page '}
                            </div>
                          </div>

                          {values.logoUrl ? (
                            <img width={480} height={300} src={values.logoUrl} />
                          ) : (
                            <div className=" w-96 h-auto px-4 py-8 bg-primary-100 text-center rounded">
                              <NavLink className="underline" to="/settings/profile">
                                Go to your profile to upload your logo.
                              </NavLink>
                            </div>
                          )}
                        </div>
                      </Accordion.Content>
                    </Accordion.Panel>
                  </Accordion>
                </div>

                <Accordion>
                  <Accordion.Panel>
                    <Accordion.Title>
                      <PanelHeader>Current Plan</PanelHeader>
                    </Accordion.Title>
                    <Accordion.Content className="pb-8">
                      <div className="grid grid-cols-3 gap-4 mt-8">
                        <HighlightedCard
                          color="bg-primary-200"
                          textColor="text-gray-700"
                          label="Current Balance"
                          value={toDollars(staticValues.currentBalance)}
                        />
                        <HighlightedCard
                          color="bg-primary-200"
                          textColor="text-gray-700"
                          label="Weighted Average Interest Rate"
                          value={toPercentage(staticValues.weightedAverageInterestRate)}
                        />
                        <HighlightedCard
                          color="bg-primary-200"
                          textColor="text-gray-700"
                          label="Current Monthly Payment"
                          value={toDollars(staticValues.currentMonthlyPayment)}
                        />
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                </Accordion>
                <Accordion>
                  <Accordion.Panel>
                    <Accordion.Title>
                      {/* TODO: order by priority, max 5 */}
                      <PanelHeader>Repayment Plans</PanelHeader>
                    </Accordion.Title>
                    <Accordion.Content className="pb-8">
                      <div className="grid gap-4 mt-8 grid-cols-2 lg:grid-cols-4">
                        {values.repaymentPlans.map((x: any, index: number) => (
                          <>
                            <div className="flex flex-col gap-1">
                              <div className="text-gray-500 ">Repayment Plan Name</div>
                              <div>{values.repaymentPlans[index].repaymentPlanType}</div>
                            </div>

                            <TextAreaField
                              className="lg:col-span-3"
                              name={`repaymentPlans[${index}].description`}
                              label="Description"
                              placeholder="Description"
                              maxLength={1000}
                              height='h-20'
                            />
                          </>
                        ))}
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                </Accordion>
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                  <Accordion className="col-span-3">
                    <Accordion.Panel>
                      <Accordion.Title>
                        <PanelHeader>New Monthly Payment Total</PanelHeader>
                      </Accordion.Title>
                      <Accordion.Content className="pb-8">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                          <FinologyTable
                            columns={[
                              {
                                title: 'Year',
                                dataIndex: 'year',
                                key: 'year',
                                render: (text: string) => text,
                              },
                              {
                                title: 'Payment',
                                dataIndex: 'payment',
                                key: 'payment',
                                render: (text: number) => toDollars(text),
                              },
                            ]}
                            rows={chunks[0]}
                            hideFooter={true}
                            type="no-promise"
                          />

                          <FinologyTable
                            columns={[
                              {
                                title: 'Year',
                                dataIndex: 'year',
                                key: 'year',
                                render: (text: string) => text,
                              },
                              {
                                title: 'Payment',
                                dataIndex: 'payment',
                                key: 'payment',
                                render: (text: number) => toDollars(text),
                              },
                            ]}
                            rows={chunks[1]}
                            hideFooter={true}
                            type="no-promise"
                          />
                        </div>
                      </Accordion.Content>
                    </Accordion.Panel>
                  </Accordion>

                  <Accordion className="col-span-1">
                    <Accordion.Panel>
                      <Accordion.Title>
                        <PanelHeader>
                          {staticValues.isTimeBasedForgivness ? 'IDR' : 'PSLF'} Summary
                        </PanelHeader>
                      </Accordion.Title>
                      <Accordion.Content className="pb-8">
                        <div className="flex flex-col">
                          <HighlightedCard
                            color="bg-primary-200"
                            textColor="text-gray-700"
                            label="Total Projected Forgiveness"
                            value={toDollars(staticValues.amountForgiven)}
                          />
                          <HighlightedCard
                            color="bg-primary-200"
                            textColor="text-gray-700"
                            label="Pro Forma Taxed Amount (Tax Bomb)"
                            value={toPercentage(staticValues.proFormaTaxedAmount)}
                          />
                          <HighlightedCard
                            color="bg-primary-200"
                            textColor="text-gray-700"
                            label="Pro Forma Asset Value"
                            value={toDollars(staticValues.proFormaAssetValue)}
                          />
                          <HighlightedCard
                            color="bg-primary-200"
                            textColor="text-gray-700"
                            label="Net Difference"
                            value={toDollars(staticValues.netDifference)}
                          />
                        </div>
                      </Accordion.Content>
                    </Accordion.Panel>
                  </Accordion>
                </div>

                <Alert
                  color="success"
                  rounded
                  className="text-gray-800  text-base flex justify-center items-center w-full font-bold"
                >
                  Sum of future payment is {toDollars(staticValues.sumOfFuturePayment)}
                </Alert>

                <Accordion>
                  <Accordion.Panel>
                    <Accordion.Title>
                      <PanelHeader>Assumptions</PanelHeader>
                    </Accordion.Title>
                    <Accordion.Content className="pb-8">
                      <div className="grid grid-cols-3 gap-4 mt-8">
                        <HighlightedCard
                          color="bg-gray-200"
                          textColor="text-gray-700"
                          label="Adjusted Gross Income"
                          value={toDollars(staticValues.adjustedGrossIncome)}
                        />
                        <HighlightedCard
                          color="bg-gray-200"
                          textColor="text-gray-700"
                          label="You have been in repayment for"
                          value={`${staticValues.yearsInRepayment} years`}
                        />
                        <HighlightedCard
                          color="bg-gray-200"
                          textColor="text-gray-700"
                          label="Annual Income Growth "
                          value={toPercentage(staticValues.annualIncomeGrowth)}
                        />
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                </Accordion>
                <Alert
                  color="success"
                  rounded
                  className="text-gray-800  text-base flex justify-center items-center w-full font-bold"
                >
                  Loan is paid off in {staticValues.paidOffYear} year.
                </Alert>
                <Accordion>
                  <Accordion.Panel>
                    <Accordion.Title>
                      <PanelHeader>Next Steps</PanelHeader>
                    </Accordion.Title>
                    <Accordion.Content className="pb-8">
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <TextAreaField
                          name="notes"
                          label="Notes"
                          placeholder="Describe what are the next steps"
                          maxLength={1000}
                          height='h-20'
                        />
                        <TextAreaField
                          className="col-span-1"
                          name="suggestions"
                          label="Suggestions"
                          placeholder="Add suggestions"
                          maxLength={1000}
                          height='h-20'
                        />
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                </Accordion>
                <Accordion>
                  <Accordion.Panel>
                    <Accordion.Title>
                      <PanelHeader>Action Items</PanelHeader>
                    </Accordion.Title>
                    <Accordion.Content className="pb-8">
                      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                        {[1, 2, 3, 4, 5, 6].map((_, index) => (
                          <>
                            <TextAreaField
                              name={`actionItem${index + 1}`}
                              label={`Action Item ${index + 1}`}
                              placeholder="Describe"
                              height='h-14'
                            />
                          </>
                        ))}
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                </Accordion>
              </div>
            </Form>

            <div className="flex justify-end gap-4 my-8">
              <ButtonWithTooltip
                color="light"
                tooltip='The "Save Report" button saves the report for later use.'
                onClick={async () => {
                  submitType.current = 'save';
                  await submitForm();
                }}
                disabled={isSubmitting}
              >
                Save Report
              </ButtonWithTooltip>
              <ButtonWithTooltip
                color="primary"
                tooltip="Exporting does not save the report for later use."
                onClick={async () => {
                  submitType.current = 'pdf';
                  await submitForm();
                }}
                disabled={isSubmitting}
              >
                Export Report
              </ButtonWithTooltip>
              <ButtonWithTooltip
                color="light"
                onClick={() => {
                  if (reportId) {
                    closeTopFlyout();
                  } else {
                    navigate(`?flyout=simulation-results`, {
                      state: {
                        calculatorInput: calculatorInput,
                        initialData: initialData,
                        simulationResults: simulationResults,
                      },
                    });
                  }
                }}
              >
                Cancel
              </ButtonWithTooltip>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export const CreateReportFlyout = () => {
  return (
    <Flyout
      id={`create-report`}
      requiredParams={['clientId', 'reportId']}
      size={'large'}
      content={<FlyoutContent />}
    ></Flyout>
  );
};
