import { Button, Modal } from 'flowbite-react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CheckboxField } from '../features/formComponents/CheckboxField';
import { CurrencyField } from '../features/formComponents/CurrencyField';
import { TextInputField } from '../features/formComponents/InputField';
import { NsldsUploadField } from '../features/formComponents/NsldsUploadField';
import SelectField from '../features/formComponents/SelectField';
import {
  MaritalStatus,
  TaxFilingType,
  useCreateSimpleClientMutation,
  useFullUpsertClientMutation,
} from '../graphql/generated';

export const UploadNsldsFileModal = ({
  isOpen = true,
  onClose,
}: {
  isOpen?: boolean;
  onClose: (value?: { firstName: string; id: number | undefined }) => void;
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [createSimpleClient] = useCreateSimpleClientMutation();
  const [upsertClient] = useFullUpsertClientMutation();
  const navigate = useNavigate();
  const params = useParams();

  const spouseId = params.clientId ? Number.parseInt(params.clientId!) : null;

  const initialValues = {
    uploadId: null,
    firstName: '',
    lastName: '',
    occupation: '',
    stateCode: '',
    isAnnualSalary: true,
    isAdjustedGrossIncome: false,
    salary: '',
    source: 'NSLDS',
  };

  const handleSubmit = async (
    v: typeof initialValues,
    formikHelpers: FormikHelpers<typeof initialValues>
  ) => {
    if (currentStep === 1) {
      setCurrentStep(2);
    } else {
      formikHelpers.setSubmitting(true);

      if (!v.uploadId) {
        const { data } = await upsertClient({
          variables: {
            input: {
              firstName: v.firstName!,
              lastName: v.lastName!,
              spouseId: spouseId,
              occupation: v.occupation!,
              stateCode: v.stateCode!,
              salary: parseFloat(v.salary),
              maritalStatus: MaritalStatus.Single,
              taxFilingType: TaxFilingType.Single,
              isItemizedDeductionDisabled: v.isAdjustedGrossIncome,
            },
          },
          refetchQueries: ['GetClientDashboard', 'GetEditClientFormDetails', 'GetClientChecklist'],
        });
        if (spouseId) {
          setCurrentStep(1);
          onClose({ firstName: v.firstName, id: data?.fullUpsertClient?.clientId });
        } else {
          navigate(`/clients/${data?.fullUpsertClient!.clientId}`);
        }
      }

      const { data } = await createSimpleClient({
        variables: {
          input: {
            firstName: v.firstName!,
            lastName: v.lastName!,
            spouseId: spouseId,
            uploadId: v.uploadId!,
            source: v.source!,
            occupation: v.occupation!,
            stateCode: v.stateCode!,
            salary: parseFloat(v.salary),
            agiInflation: v.isAdjustedGrossIncome,
          },
        },
        refetchQueries: ['GetClientDashboard', 'GetEditClientFormDetails', 'GetClientChecklist'],
      });

      if (spouseId) {
        setCurrentStep(1);
        onClose({ firstName: v.firstName, id: data?.createSimpleClient?.clientId });
      } else {
        navigate(`/clients/${data?.createSimpleClient!.clientId}`);
      }
    }
  };

  return (
    <Modal show={isOpen} className="bg-black bg-opacity-80" size={'lg'}>
      <div className="border border-b border-gray-200 p-4 rounded-t-lg">
        <div className="font-medium text-gray-900 text-lg ml-6">
          {currentStep === 1 ? 'Upload NSLDS File' : 'Add Client Info'}
        </div>
      </div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form>
            <Modal.Body className="p-0">
              {currentStep === 1 ? (
                <div className="flex flex-col items-center justify-center gap-4 mb-4 p-4">
                  <Field name="uploadId">
                    {({ field }: any) => (
                      <NsldsUploadField
                        {...field}
                        onUpload={(file: File) => {
                          setFieldValue('uploadId', file);
                        }}
                      />
                    )}
                  </Field>
                </div>
              ) : (
                <div className="p-4">
                  <div className="grid grid-cols-2 gap-4">
                    <TextInputField name="firstName" placeholder="First Name" />
                    <TextInputField name="lastName" placeholder="Last Name" />
                    <TextInputField name="occupation" placeholder="Occupation" />
                    <SelectField name="stateCode" label="State" allowClear={false}>
                      <option value="AL"> Alabama</option>
                      <option value="AK"> Alaska</option>
                      <option value="AZ"> Arizona</option>
                      <option value="AR"> Arkansas</option>
                      <option value="CA"> California</option>
                      <option value="CO"> Colorado</option>
                      <option value="CT"> Connecticut</option>
                      <option value="DE"> Delaware</option>
                      <option value="DC">District of Columbia (Washington DC)</option>
                      <option value="FL"> Florida</option>
                      <option value="GA"> Georgia</option>
                      <option value="HI"> Hawaii</option>
                      <option value="ID"> Idaho</option>
                      <option value="IL"> Illinois</option>
                      <option value="IN"> Indiana</option>
                      <option value="IA"> Iowa</option>
                      <option value="KS"> Kansas</option>
                      <option value="KY"> Kentucky</option>
                      <option value="LA"> Louisiana</option>
                      <option value="ME"> Maine</option>
                      <option value="MD"> Maryland</option>
                      <option value="MA"> Massachusetts</option>
                      <option value="MI"> Michigan</option>
                      <option value="MN"> Minnesota</option>
                      <option value="MS"> Mississippi</option>
                      <option value="MO"> Missouri</option>
                      <option value="MT"> Montana</option>
                      <option value="NE"> Nebraska</option>
                      <option value="NV"> Nevada</option>
                      <option value="NH"> New Hampshire</option>
                      <option value="NJ"> New Jersey</option>
                      <option value="NM"> New Mexico</option>
                      <option value="NY"> New York</option>
                      <option value="NC"> North Carolina</option>
                      <option value="ND"> North Dakota</option>
                      <option value="OH"> Ohio</option>
                      <option value="OK"> Oklahoma</option>
                      <option value="OR"> Oregon</option>
                      <option value="PA"> Pennsylvania</option>
                      <option value="PR"> Puerto Rico</option>
                      <option value="RI"> Rhode Island</option>
                      <option value="SC"> South Carolina</option>
                      <option value="SD"> South Dakota</option>
                      <option value="TN"> Tennessee</option>
                      <option value="TX"> Texas</option>
                      <option value="US"> US Territory</option>
                      <option value="UT"> Utah</option>
                      <option value="VT"> Vermont</option>
                      <option value="VA"> Virginia</option>
                      <option value="WA"> Washington</option>
                      <option value="WV"> West Virginia</option>
                      <option value="WI"> Wisconsin</option>
                      <option value="WY"> Wyoming</option>
                    </SelectField>
                    <div className="col-span-1">
                      <div>Income Type</div>
                      <div className="flex flex-col gap-1 mt-2">
                        <div className="flex items-center">
                          <CheckboxField
                            label="Annual Salary"
                            name="isAnnualSalary"
                            onChange={(e) => {
                              setFieldValue('isAdjustedGrossIncome', !e.target.checked);
                            }}
                          />
                        </div>
                        <div>
                          <CheckboxField
                            onChange={(e) => {
                              setFieldValue('isAnnualSalary', !e.target.checked);
                            }}
                            name="isAdjustedGrossIncome"
                            label="Adjusted Gross Income"
                            className="mr-2"
                          />
                        </div>
                      </div>
                    </div>
                    <CurrencyField name="salary" placeholder="e.g. 80,000" label="Salary" />
                  </div>
                </div>
              )}
            </Modal.Body>
            <div className="w-full px-4 py-4 border-t border-gray-200">
              <div className="flex flex-row w-full gap-2 justify-end">
                {currentStep === 1 ? (
                  <>
                    <Button color="light" onClick={() => onClose()}>
                      Cancel
                    </Button>
                    <Button type="submit">Next</Button>
                  </>
                ) : (
                  <>
                    <Button type="button" color="light" onClick={() => setCurrentStep(1)}>
                      Back
                    </Button>
                    <Button type="submit">Create Client</Button>
                  </>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
