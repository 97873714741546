import React from 'react';

import withSkeleton from './withSkeleton';

interface FinologyCardProps {
  title: string;
  totalAmount: string | number | null | undefined;
  averageAmount: string | number | null | undefined;
  variant?: 'default' | 'blue' | 'green' | 'orange';
}

const getColor = (variant: FinologyCardProps['variant']) => {
  switch (variant) {
    case 'blue':
      return 'bg-primary-400';
    case 'green':
      return 'bg-green-300';
    case 'orange':
      return 'bg-orange-300';
    default:
      return 'bg-gray-400';
  }
};

const FinologyCard: React.FC<FinologyCardProps & { isLoading: boolean }> = ({
  title,
  totalAmount,
  averageAmount,
  variant = 'default',
}) => {
  const color = getColor(variant);

  return (
    <div className={`bg-white rounded-2xl shadow-md overflow-hidden`}>
      <div className={`h-4 ${color} rounded-t-2xl`} />
      <div className="p-6">
        <h2 className="text-sm font-semibold text-gray-800 mb-6">{title}</h2>
        <div className="flex justify-start w-[275px]">
          <div className="pr-4">
            <p className="text-gray-600 uppercase text-xs mb-2">TOTAL</p>
            <p className="text-md font-bold text-gray-800">{totalAmount}</p>
          </div>
          <div className="border-l pl-8">
            <p className="text-gray-600 uppercase text-xs mb-2">AVERAGE</p>
            <p className="text-md font-bold text-gray-800">{averageAmount}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSkeleton(FinologyCard);
