import classNames from 'classnames';
import { Label, Spinner } from 'flowbite-react';
import { useFormikContext } from 'formik';
import { createRef, useEffect, useRef, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { GoXCircleFill } from 'react-icons/go';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { RiFileFill } from 'react-icons/ri';
import { TbFileImport } from 'react-icons/tb';

import { useUploadNsldsMutation } from '../../graphql/generated';

import { TextInputField } from './InputField';

interface Props {
  name: string;
  placeholder: string;
}

export const NsldsUploadField = ({ name, placeholder }: Props) => {
  const [uploadNsldsMutation] = useUploadNsldsMutation();

  const context = useFormikContext<any>();
  const value = context.values[name];

  const dropRef = createRef<HTMLDivElement>();
  const [isDragging, setIsDragging] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const [fileName, setSelectedFileName] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadingError, setUploadingError] = useState<string | null>(null);

  const error =
    (!!context.touched[name] || context.submitCount) && !!context.errors[name] && !uploading;

  const preventDefaults = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: DragEvent) => {
    preventDefaults(e);
    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) return;
    setIsDragging(true);
  };

  const handleDragOver = (e: DragEvent) => {
    preventDefaults(e);
  };

  const handleDragOut = (e: DragEvent) => {
    preventDefaults(e);
    setIsDragging(false);
  };

  const handleDrop = async (e: DragEvent) => {
    preventDefaults(e);
    setIsDragging(false);
    if (
      e.dataTransfer &&
      e.dataTransfer.files &&
      e.dataTransfer.files.length > 0 &&
      e.dataTransfer.files[0].type.includes('text')
    ) {
      await handleFileSelected(e.dataTransfer.files);

      e.dataTransfer.clearData();
    }
  };

  const handleFileSelected = async (files: FileList | null) => {
    context.setFieldTouched(name);

    if (!files) return;

    setSelectedFileName(files[0].name);

    setUploading(true);
    try {
      const { data } = await uploadNsldsMutation({
        variables: { file: files[0] },
        context: {
          headers: {
            'GraphQL-preflight': 1,
          },
        },
      });
      // TODO
      // .catch((error) => {
      //   setUploadingError(error.message);
      // });

      setUploading(false);

      context.setFieldValue(name, data?.uploadNslds!.uploadId);
      context.setFieldValue('source', data?.uploadNslds!.source);
      if (data?.uploadNslds!.source) {
        setUploadingError(null);
      }
    } catch (error) {
      setUploadingError('Error while uploading the NSLDS file');
    }
  };

  useEffect(() => {
    const div = dropRef.current;
    if (div) {
      div.addEventListener('dragenter', handleDragIn);
      div.addEventListener('dragleave', handleDragOut);
      div.addEventListener('drop', handleDrop);
      div.addEventListener('dragover', handleDragOver);
    }

    return () => {
      if (div) {
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('drop', handleDrop);
        div.removeEventListener('dragover', handleDragOver);
      }
    };
  }, []);

  const UploadSection = () => {
    return (
      <div className="flex flex-col gap-y-2 justify-center items-center w-full">
        <div>
          <TbFileImport className="text-primary-800" size={40} />
        </div>
        <div className="flex flex-col items-center justify-center ">
          <div className="text-primary-800">Upload from computer</div>
          <div className="text-gray-600 text-xs"> drag &amp; drop or&nbsp;the file</div>
        </div>
        <input
          type="file"
          ref={inputRef}
          hidden
          onChange={async (e) => {
            await handleFileSelected(e.target.files);
          }}
        />
      </div>
    );
  };

  const FileNameSection = () => {
    const error = uploading && fileName;
    return (
      <div className={`border border-gray-200 bg-gray-100 w-full ${!fileName ? 'p-4' : 'p-1'}`}>
        <div className="flex flex-row justify-between items-center px-2 py-1 ">
          <div className="flex flex-row gap-x-2 items-center">
            {fileName ? <RiFileFill size={32} fill="gray" /> : <div className="h-8"></div>}
            <div className="flex flex-col">
              <div className={`${error ? 'text-red-800' : ''}`}>{fileName}</div>
              <div>
                {fileName && (
                  <button
                    className="text-xs"
                    type="button"
                    onClick={() => {
                      context.setFieldValue(name, undefined);
                      context.setFieldValue('source', undefined);

                      setSelectedFileName(null);

                      inputRef.current!.files = null;
                    }}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          </div>
          <div>
            {fileName ? (
              error ? (
                <GoXCircleFill
                  className="text-red-600"
                  size={24}
                  onClick={() => {
                    context.setFieldValue(name, undefined);
                    context.setFieldValue('source', undefined);

                    setSelectedFileName(null);

                    inputRef.current!.files = null;
                  }}
                />
              ) : (
                <FaCheckCircle className="text-green-500" size={24} />
              )
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-y-3 w-full" ref={dropRef}>
      <Label>{placeholder}</Label>
      <div
        onClick={() => {
          inputRef.current!.click();
        }}
        className={classNames(
          'flex bg-primary-50 rounded-lg border-2 border-primary-200 py-7 px-6 items-center',
          {
            'border-red': error && !isDragging,
            'border-gray-200': !error && !isDragging,
            'border-gray-600': isDragging,
          }
        )}
      >
        <UploadSection />
      </div>

      <div className="flex flex-col ">
        <div className="text-gray-900 text-xs mb-2">Uploaded file</div>
        <FileNameSection />
      </div>

      {uploading && !fileName ? (
        <div className="flex items-center gap-x-2">
          <Spinner size={'sm'} />
          <span>Processing file...</span>
        </div>
      ) : value ? (
        <div className="flex flex-col ">
          <TextInputField name="source" placeholder="Version" className="mt-2" />
        </div>
      ) : value === null && error ? (
        <div className="flex items-center gap-x-2 text-red-500">
          <HiOutlineExclamationCircle />
          <span>
            File processing failed <br />
            Please check your file or try using another one
          </span>
        </div>
      ) : null}
    </div>
  );
};
