import { Accordion, Button, Alert } from 'flowbite-react';
import { FieldArray, Form, Formik, FormikErrors } from 'formik';
import { useEffect, useState } from 'react';
import { HiInformationCircle } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';

import { PanelHeader } from '../../../../components/Accordion/PanelHeader';
import { Flyout, useFlyoutContext } from '../../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../../components/flyout/FlyoutHeader';
import { FormErrorNotification } from '../../../../components/forms/FormErrorNotification';
import {
  CreditCardLoanDtoInput,
  useGetCreditCardLoanDetailsLazyQuery,
  useInsertCreditCardLoansMutation,
  useUpsertCreditCardLoanMutation,
} from '../../../../graphql/generated';
import { notification } from '../../../../util/notification.utils';
import { CurrencyField } from '../../../formComponents/CurrencyField';
import { DateField } from '../../../formComponents/DateField';
import { TextInputField } from '../../../formComponents/InputField';
import { PercentageField } from '../../../formComponents/PercentageField';
import SelectField from '../../../formComponents/SelectField';


type FormValues = Partial<CreditCardLoanDtoInput>;

const FlyoutContent = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const creditCardId = params.get('creditCardId');
  const clientId = Number.parseInt(params.get('clientId')!);
  const [showUpdateScenarioModal, setShowUpdateScenarioModal] = useState(false);
  const [multipleLoans, setMultipleLoans] = useState<CreditCardLoanDtoInput[]>();
  const [dismissAlert, setDismissAlert] = useState(false);

  const parsedCreditCardId = creditCardId ? Number.parseInt(creditCardId) : null;

  const [getCreditCardLoan] = useGetCreditCardLoanDetailsLazyQuery();

  const [upsertCreditCardLoan] = useUpsertCreditCardLoanMutation();

  const [initialValues, setInitialValues] = useState<FormValues>({
    clientId: clientId,
  });

  const { closeTopFlyout } = useFlyoutContext();

  useEffect(() => {
    async function initialize() {
      if (parsedCreditCardId == null) return;

      const { data } = await getCreditCardLoan({ variables: { loanId: parsedCreditCardId } });

      if (!data?.creditCardLoan) return;

      const { creditCardLoan } = data;

      setInitialValues({
        bankName: creditCardLoan.bankName,
        cardName: creditCardLoan.cardName,
        type: creditCardLoan.type,
        clientId: creditCardLoan.clientId,
        id: creditCardLoan.id,
        last4Digits: creditCardLoan.last4Digits,
        cardOpenDate: creditCardLoan.cardOpenDate,
        cardCloseDate: creditCardLoan.cardCloseDate,
        outstandingBalance: creditCardLoan.outstandingBalance,
        outstandingBalanceDate: creditCardLoan.outstandingBalanceDate,
        balanceTransferApr: creditCardLoan.balanceTransferApr,
        purchaseApr: creditCardLoan.purchaseApr,
        cashAdvanceApr: creditCardLoan.cashAdvanceApr,
        balanceTransferRate: creditCardLoan.balanceTransferRate,
        cashAdvanceRate: creditCardLoan.cashAdvanceRate,
        transactionFees: creditCardLoan.transactionFees,
        penaltyFees: creditCardLoan.penaltyFees,
        creditCardLimit: creditCardLoan.creditCardLimit,
        minimumPayments: creditCardLoan.minimumPayments,
      });
    }

    initialize();
  }, []);

  const onUpload = (response: CreditCardLoanDtoInput[]) => {
    const withClientIdResponse = response.map((r) => {
      return { ...r, clientId: clientId };
    });

    setMultipleLoans(withClientIdResponse);
  };

  const showMultipleLoansComparison = multipleLoans?.length;

  if (showMultipleLoansComparison)
    return <MultipleLoansView clientId={clientId} loans={multipleLoans} />;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};
        if (values.last4Digits && values.last4Digits.length !== 4) {
          errors.last4Digits = 'Exactly 4 characters';
        }

        if (values.creditCardLimit && values.outstandingBalance) {
          if (values.creditCardLimit < values.outstandingBalance) {
            errors.creditCardLimit = 'Should be greater than balance';
          }
        }

        if (values.minimumPayments && values.creditCardLimit) {
          if (values.minimumPayments > values.creditCardLimit) {
            errors.minimumPayments = 'Should be less than limit';
          }
        }

        return errors;
      }}
      onSubmit={async (v, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        setShowUpdateScenarioModal(false);

        await upsertCreditCardLoan({
          variables: {
            input: {
              loan: {
                id: v.id,
                clientId: v.clientId!,
                bankName: v.bankName!,
                cardName: v.cardName,
                last4Digits: v.last4Digits,
                cardOpenDate: v.cardOpenDate,
                cardCloseDate: v.cardCloseDate,
                outstandingBalance: v.outstandingBalance,
                outstandingBalanceDate: v.outstandingBalanceDate,
                balanceTransferApr: v.balanceTransferApr,
                purchaseApr: v.purchaseApr,
                cashAdvanceApr: v.cashAdvanceApr,
                balanceTransferRate: v.balanceTransferRate,
                cashAdvanceRate: v.cashAdvanceRate,
                transactionFees: v.transactionFees,
                penaltyFees: v.penaltyFees,
                creditCardLimit: v.creditCardLimit,
                minimumPayments: v.minimumPayments,
                type: v.type,
              },
            },
          },
          refetchQueries: [
            'GetCreditCardLoans',
            'GetClientDashboard',
            'CreditCardAggregations',
            'GetClientChecklist',
          ],
        });

        notification.success({
          placement: 'bottom-center',
          message: v.id ? 'Credit card has been saved' : 'Credit card has been created',
        });

        closeTopFlyout();
      }}
    >
      {({ isSubmitting }) => {
        return (
          <>
            {/* <Modal show={isSubmitting || showUpdateScenarioModal} onClose={() => closeTopFlyout()}>
              <Modal.Header>Apply Changes to Scenarios</Modal.Header>
              <Modal.Body>
                <p className="font-bold text-md mb-4">
                  Would you like to apply the changes made to the loan to the scenarios using this
                  version?
                </p>
                <div className="flex justify-end gap-2">
                  <Button
                    type="button"
                    color="light"
                    onClick={() => {
                      setShowUpdateScenarioModal(false);
                      closeTopFlyout();
                    }}
                  >
                    Do not Update
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      setShowUpdateScenarioModal(false);
                      // handle update scenario
                    }}
                  >
                    Update Scenarios
                  </Button>
                </div>
              </Modal.Body>
            </Modal> */}

            <FormErrorNotification />
            <FlyoutHeader
              primaryButton={{
                form: 'upsert-credit-card-form',
                children: 'Save',
                disabled: isSubmitting,
              }}
              secondaryButton={{
                onClick: closeTopFlyout,
                children: 'Cancel',
              }}
              label={creditCardId ? 'Edit Credit Card' : 'Add Credit Card'}
            />
            {/* {!creditCardId ? (
            <>
              <>
                <Accordion>
                    <Accordion.Panel collapseAll={true}>
                      <Accordion.Title>
                        <PanelHeader>Upload files</PanelHeader>
                      </Accordion.Title>
                      <Accordion.Content>
                        <Form>
                          <UploadField name="creditCard" onUpload={onUpload} />
                        </Form>
                      </Accordion.Content>
                    </Accordion.Panel>
                  </Accordion>
                </>

                <div className="flex items-center gap-x-2 my-6">
                  <div className="border-t border-gray-300 w-full"></div>
                  <div className="text-gray-400">or</div>
                  <div className="border-t border-gray-300 w-full"></div>
                </div>
              </>
            ) : null} */}

            <Form id={'upsert-credit-card-form'}>
              {!dismissAlert && (
                <Alert
                  color="info"
                  className="mb-6"
                  icon={HiInformationCircle}
                  title="Get the Credit Card Agreement"
                  onDismiss={() => setDismissAlert(true)}
                  additionalContent={
                    <div className="mt-4">
                      <p>
                        A credit card agreement is a legal contract between a credit card issuer
                        (usually a bank or financial institution) and a cardholder. It outlines the
                        terms and conditions that govern the use of the credit card which will help
                        to get the best results.
                      </p>
                      <p className="mt-2">
                        Credit Card Agreement File Upload option is coming soon.
                      </p>
                    </div>
                  }
                >
                  <span className="font-semibold">Get the Credit Card Agreement</span>
                </Alert>
              )}

              <div className="grid grid-cols-2 gap-x-2 gap-y-2 items-end">
                <TextInputField
                  name="bankName"
                  placeholder="Bank Name"
                  className="col-span-1"
                  sizing="md"
                />
                <SelectField
                  name="type"
                  size="sm"
                  label="type"
                  styles={{
                    control: {
                      padding: 2,
                    },
                  }}
                >
                  <option value="REVOLVING">Revolving</option>
                  <option value="OPEN">Open</option>
                  <option value="CLOSED">Closed</option>
                </SelectField>
                <TextInputField name="cardName" placeholder="Credit Card Name" sizing="md" />

                <TextInputField
                  name="last4Digits"
                  placeholder="XXXX"
                  label="Last 4 Digits"
                  sizing="md"
                />

                <DateField
                  name="cardOpenDate"
                  placeholder="mm/dd/yyyy"
                  label="Card Open Date"
                  sizing="md"
                />
                <DateField
                  name="cardCloseDate"
                  placeholder="mm/dd/yyyy"
                  label="Card Close Date"
                  sizing="md"
                />
                <CurrencyField
                  name="outstandingBalance"
                  placeholder="Outstanding Balance"
                  sizing="md"
                />
                <DateField
                  name="outstandingBalanceDate"
                  placeholder="mm/dd/yyyy"
                  label="Outstanding Balance as of Date"
                  sizing="md"
                />
              </div>
              <div className="grid grid-cols-3 gap-x-2 gap-y-2 mt-2 items-end">
                <PercentageField
                  name="balanceTransferApr"
                  placeholder="00.00"
                  label="Balance APR"
                  sizing="md"
                />
                <PercentageField
                  name="purchaseApr"
                  placeholder="00.00"
                  label="Purchase APR"
                  sizing="md"
                />
                <PercentageField
                  name="cashAdvanceApr"
                  placeholder="00.00"
                  label="Cash Advance APR"
                  sizing="md"
                />
                <PercentageField
                  name="balanceTransferRate"
                  placeholder="00.00"
                  label="Balance Transfer Rate"
                  sizing="md"
                />
                <PercentageField
                  name="cashAdvanceRate"
                  placeholder="00.00"
                  label="Cash Advance Rate"
                  sizing="md"
                />
                <CurrencyField
                  name="transactionFees"
                  placeholder="0000"
                  label="Transaction Fees"
                  sizing="md"
                />
                <CurrencyField
                  name="penaltyFees"
                  placeholder="0000"
                  label="Penalty Fees"
                  sizing="md"
                />
                <CurrencyField
                  name="creditCardLimit"
                  placeholder="0000"
                  label="Credit Card Limit"
                  sizing="md"
                />
                <CurrencyField
                  name="minimumPayments"
                  placeholder="0000"
                  label="Monthly Minimum Payment"
                  sizing="md"
                />
              </div>
            </Form>

            <div className="flex justify-start mb-2 gap-4">
              <Button
                form="upsert-credit-card-form"
                disabled={isSubmitting}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                type="submit"
                className="mt-4 px-8"
              >
                Save
              </Button>
              <Button
                form="upsert-credit-card-form"
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                onClick={closeTopFlyout}
                color="light"
                className="mt-3 px-8"
              >
                Cancel
              </Button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};
const MultipleLoansView = ({
  loans,
  clientId,
}: {
  loans: CreditCardLoanDtoInput[];
  clientId: number;
}) => {
  const [insertLoans] = useInsertCreditCardLoansMutation();
  const { closeTopFlyout } = useFlyoutContext();

  return (
    <div className="mt-6">
      <div className="flex justify-between mb-6">
        <div className="text-2xl mb-4">Confirm your loans</div>
        <Button type="submit" form="multiple-credit-card-loans-insert" color="primary" size="sm">
          Confirm
        </Button>
      </div>
      <Formik
        initialValues={{
          loans: loans,
        }}
        validate={(values) => {
          const errors: FormikErrors<any> = {};
          values.loans.forEach((loan, index) => {
            if (loan.last4Digits && loan.last4Digits.length !== 4) {
              errors[`loans[${index}].last4Digits`] = 'Exactly 4 characters';
            }

            if (loan.creditCardLimit && loan.outstandingBalance) {
              if (loan.creditCardLimit < loan.outstandingBalance) {
                errors[`loans[${index}].creditCardLimit`] = 'Should be greater than balance';
              }
            }

            if (loan.minimumPayments && loan.creditCardLimit) {
              if (loan.minimumPayments > loan.creditCardLimit) {
                errors[`loans[${index}].minimumPayments`] = 'Should be less than limit';
              }
            }
          });

          return errors;
        }}
        enableReinitialize={true}
        onSubmit={async (values) => {
          await insertLoans({
            variables: {
              input: {
                clientId: clientId,
                loansToInsert: values.loans,
              },
            },
            refetchQueries: [
              'GetCreditCardLoans',
              'GetClientDashboard',
              'CreditCardAggregations',
              'GetClientChecklist',
            ],
          });

          notification.success({
            message: 'Credit Card Loans Created Successfully',
            placement: 'bottom-center',
          });

          closeTopFlyout();
        }}
      >
        {({ values, initialValues }) => (
          <Form id="multiple-credit-card-loans-insert">
            <FieldArray name="loans">
              {({ remove }) =>
                values.loans.map((loan: FormValues, index: number) => (
                  <Accordion
                    key={initialValues.loans[index].bankName + index}
                    className="mt-4"
                    collapseAll={!!index}
                  >
                    <Accordion.Panel collapseAll={true}>
                      <Accordion.Title>
                        <PanelHeader>{initialValues.loans[index].bankName}</PanelHeader>
                      </Accordion.Title>
                      <Accordion.Content>
                        <div className="flex justify-end items-center">
                          <div className="flex gap-x-2">
                            <Button color="light" size="sm" onClick={() => remove(index)}>
                              Delete
                            </Button>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-2 gap-y-2 items-end">
                          <TextInputField
                            name={`loans[${index}].bankName`}
                            placeholder="Bank Name"
                            sizing="md"
                          />
                          <TextInputField
                            name={`loans[${index}].cardName`}
                            placeholder="Credit Card Name"
                            sizing="md"
                          />
                          <SelectField
                            name={`loans[${index}].type`}
                            size="md"
                            styles={{
                              control: {
                                padding: 2,
                              },
                            }}
                          >
                            <option value="REVOLVING">REVOLVING</option>
                            <option value="OPEN">OPEN</option>
                            <option value="CLOSED">CLOSED</option>
                          </SelectField>
                          <TextInputField
                            name={`loans[${index}].type`}
                            placeholder="Type"
                            sizing="md"
                          />
                          <TextInputField
                            name={`loans[${index}].last4Digits`}
                            placeholder="XXXX"
                            label="Last 4 Digits"
                            sizing="md"
                          />
                          <DateField
                            name={`loans[${index}].cardOpenDate`}
                            placeholder="mm/dd/yyyy"
                            label="Card Open Date"
                            sizing="md"
                          />
                          <DateField
                            name={`loans[${index}].cardCloseDate`}
                            placeholder="mm/dd/yyyy"
                            label="Card Close Date"
                            sizing="md"
                          />
                          <CurrencyField
                            name={`loans[${index}].outstandingBalance`}
                            placeholder="Outstanding Balance"
                            sizing="md"
                          />
                          <DateField
                            name={`loans[${index}].outstandingBalanceDate`}
                            placeholder="mm/dd/yyyy"
                            label="Outstanding Balance as of Date"
                            sizing="md"
                          />{' '}
                        </div>
                        <div className="grid grid-cols-3 gap-x-2 gap-y-2 mt-2 items-end">
                          <PercentageField
                            name={`loans[${index}].balanceTransferApr`}
                            placeholder="00.00"
                            label="Balance APR"
                            sizing="md"
                          />
                          <PercentageField
                            name={`loans[${index}].purchaseApr`}
                            placeholder="00.00"
                            label="Purchase APR"
                            sizing="md"
                          />
                          <PercentageField
                            name={`loans[${index}].cashAdvanceApr`}
                            placeholder="00.00"
                            label="Cash Advance APR"
                            sizing="md"
                          />
                          <PercentageField
                            name={`loans[${index}].balanceTransferRate`}
                            placeholder="00.00"
                            label="Balance Transfer Rate"
                            sizing="md"
                          />
                          <PercentageField
                            name={`loans[${index}].cashAdvanceRate`}
                            placeholder="00.00"
                            label="Cash Advance Rate"
                            sizing="md"
                          />
                          <CurrencyField
                            name={`loans[${index}].transactionFees`}
                            placeholder="0000.00"
                            label="Transaction Fees"
                            sizing="md"
                          />
                          <CurrencyField
                            name={`loans[${index}].penaltyFees`}
                            placeholder="0000.00"
                            label="Penalty Fees"
                            sizing="md"
                          />
                          <CurrencyField
                            name={`loans[${index}].creditCardLimit`}
                            placeholder="0000.00"
                            label="Credit Card Limit"
                            sizing="md"
                          />
                          <CurrencyField
                            name={`loans[${index}].minimumPayments`}
                            placeholder="0000.00"
                            label="Monthly Minimum Payment"
                            sizing="md"
                          />
                        </div>
                      </Accordion.Content>
                    </Accordion.Panel>
                  </Accordion>
                ))
              }
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const UpsertCreditCardFlyout = () => {
  return (
    <Flyout
      id={`upsert-credit-card`}
      requiredParams={['loanId', 'clientId']}
      size={'small'}
      content={<FlyoutContent />}
    ></Flyout>
  );
};
