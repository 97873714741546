import { Button, Dropdown } from 'flowbite-react';
import { useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { PageHeader } from '../components/PageHeader';
import { ClientDetails } from '../features/clients/details/ClientDetails';
import { useFlyoutNavigate } from '../hooks/useFlyoutNavigate';
import useFinologyTranslation from '../translations/useFinologyTranslation';


export const Client = () => {
  const params = useParams();
  const clientId = Number.parseInt(params.clientId!);
  const location = useLocation();
  const { navigate: flyoutNavigate } = useFlyoutNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const { t } = useFinologyTranslation();
  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            to: location.pathname.startsWith('/clients')
              ? '/clients'
              : `${location.pathname.replace(`/clients/${clientId}`, '')}`,
            text: `${t('borrower.uppercase')} Details`,
          },
        ]}
        rightContent={
          <>
            <Dropdown
              color="light"
              arrowIcon={false}
              label={
                <>
                  <HiPlus /> &nbsp; Add Loan
                </>
              }
            >
              <Dropdown.Item
                onClick={() => {
                  setActiveTab(1);
                  flyoutNavigate('upsert-federal-loan', `clientId=${clientId}`);
                }}
              >
                Federal
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setActiveTab(2);
                  flyoutNavigate('upsert-private-loan', `clientId=${clientId}`);
                }}
              >
                Private
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setActiveTab(2);
                  flyoutNavigate('upsert-credit-card', `clientId=${clientId}`);
                }}
              >
                Credit Card
              </Dropdown.Item>
            </Dropdown>
            <Button
              color="light"
              onClick={() => flyoutNavigate('upsert-client', `clientId=${clientId}`)}
            >
              Client Details
            </Button>

            <div className="border-l border-gray-300 min-h-full mx-1"></div>
            <Button
              color="light"
              onClick={() => flyoutNavigate('client-federal-loan-versions', `clientId=${clientId}`)}
            >
              {' '}
              Versions
            </Button>
            <Button color="primary" onClick={() => navigate(`${location.pathname}/calculator`)}>
              Liability Planner
            </Button>
          </>
        }
        back
      />

      <ClientDetails clientId={clientId} />
    </>
  );
};
