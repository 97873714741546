import dayjs from 'dayjs';
import { Button, Spinner } from 'flowbite-react';
import { Accordion } from 'flowbite-react';
import { FormikErrors, Form, Formik } from 'formik';
import { ReactNode, useEffect, useState } from 'react';
import { HiCheckCircle } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';

import { Flyout, useFlyoutContext } from '../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../components/flyout/FlyoutHeader';
import { FormErrorNotification } from '../../../components/forms/FormErrorNotification';
import { useGlobalState } from '../../../context/AppContext';
import {
  ClientFullUpsertInput,
  MaritalStatus,
  TaxFilingType,
  Title,
  useFullUpsertClientMutation,
  useGetEditClientFormDetailsLazyQuery,
  useGetEditClientFormDetails_SpouseLazyQuery,
} from '../../../graphql/generated';
import { useFlyoutNavigate } from '../../../hooks/useFlyoutNavigate';
import { useScrollIntoView } from '../../../hooks/useScrollIntoView';
import { UploadNsldsFileModal } from '../../../modals/UploadNsldsFileModal';
import useFinologyTranslation from '../../../translations/useFinologyTranslation';
import { notification } from '../../../util/notification.utils';
import { isClient } from '../../../utils';
import { RadioGroupField } from '../../formComponents/CheckboxField';
import { ClientSelectorField } from '../../formComponents/ClientSelectorField';
import { CurrencyField } from '../../formComponents/CurrencyField';
import { DateField } from '../../formComponents/DateField';
import FutureChildrenInput from '../../formComponents/FutureChildrenInput';
import { TextInputField } from '../../formComponents/InputField';
import { PercentageField } from '../../formComponents/PercentageField';
import { ProfessionSelectField } from '../../formComponents/ProfessionSelectField';
import SelectField from '../../formComponents/SelectField';
import { SwitchField } from '../../formComponents/SwitchField';
import { TextAreaField } from '../../formComponents/TextAreaField';

const STATE_TAX_RATE = [
  { value: 0.05, id: 'AL', label: 'AL-5.00%' },
  { value: 0, id: 'AK', label: 'AK-0.00%' },
  { value: 0.0454, id: 'AZ', label: 'AZ-4.54%' },
  { value: 0.069, id: 'AR', label: 'AR-6.90%' },
  { value: 0.131, id: 'CA', label: 'CA-13.10%' },
  { value: 0.0463, id: 'CO', label: 'CO-4.63%' },
  { value: 0.0699, id: 'CT', label: 'CT-6.99%' },
  { value: 0.1075, id: 'DC', label: 'DC-10.75%' },
  { value: 0.066, id: 'DE', label: 'DE-6.60%' },
  { value: 0, id: 'FL', label: 'FL-0.00%' },
  { value: 0.0575, id: 'GA', label: 'GA-5.75%' },
  { value: 0.11, id: 'HI', label: 'HI-11.00%' },
  { value: 0.0853, id: 'IA', label: 'IA-8.53%' },
  { value: 0.0693, id: 'ID', label: 'ID-6.93%' },
  { value: 0.0495, id: 'IL', label: 'IL-4.95%' },
  { value: 0.0332, id: 'IN', label: 'IN-3.32%' },
  { value: 0.057, id: 'KS', label: 'KS-5.70%' },
  { value: 0.05, id: 'KY', label: 'KY-5.00%' },
  { value: 0.06, id: 'LA', label: 'LA-6.00%' },
  { value: 0.0575, id: 'MD', label: 'MD-5.75%' },
  { value: 0.0715, id: 'ME', label: 'ME-7.15%' },
  { value: 0.0505, id: 'MA', label: 'MA-5.05%' },
  { value: 0.0425, id: 'MI', label: 'MI-4.25%' },
  { value: 0.0985, id: 'MN', label: 'MN-9.85%' },
  { value: 0.05, id: 'MS', label: 'MS-5.00%' },
  { value: 0.054, id: 'MO', label: 'MO-5.40%' },
  { value: 0.069, id: 'MT', label: 'MT-6.90%' },
  { value: 0.0475, id: 'NC', label: 'NC-4.75%' },
  { value: 0.029, id: 'ND', label: 'ND-2.90%' },
  { value: 0.0684, id: 'NE', label: 'NE-6.84%' },
  { value: 0.05, id: 'NH', label: 'NH-5.00%' },
  { value: 0.1075, id: 'NJ', label: 'NJ-10.75%' },
  { value: 0.049, id: 'NM', label: 'NM-4.90%' },
  { value: 0.029, id: 'NO', label: 'NO-2.90%' },
  { value: 0.0, id: 'NV', label: 'NV-0.00%' },
  { value: 0.0882, id: 'NY', label: 'NY-8.82%' },
  { value: 0.05, id: 'OH', label: 'OH-5.00%' },
  { value: 0.05, id: 'OK', label: 'OK-5.00%' },
  { value: 0.099, id: 'OR', label: 'OR-9.90%' },
  { value: 0.0307, id: 'PA', label: 'PA-3.07%' },
  { value: 0.33, id: 'PR', label: 'PR-33.00%' },
  { value: 0.0599, id: 'RI', label: 'RI-5.99%' },
  { value: 0.07, id: 'SC', label: 'SC-7.00%' },
  { value: 0.0, id: 'SD', label: 'SD-0.00%' },
  { value: 0.02, id: 'TN', label: 'TN-2.00%' },
  { value: 0, id: 'TX', label: 'TX-0.00%' },
  { value: 0.0495, id: 'UT', label: 'UT-4.95%' },
  { value: 0.0875, id: 'VT', label: 'VT-8.75%' },
  { value: 0.0575, id: 'VA', label: 'VA-5.75%' },
  { value: 0, id: 'WA', label: 'WA-0.00%' },
  { value: 0.065, id: 'WV', label: 'WV-6.50%' },
  { value: 0.0765, id: 'WI', label: 'WI-7.65%' },
  { value: 0, id: 'WY', label: 'WY-0.00%' },
];

type FormValues = { spouse?: number | null } & Omit<
  ClientFullUpsertInput,
  'taxFilingType' | 'maritalStatus'
> & {
    maritalStatus?: MaritalStatus;
    taxFilingType?: TaxFilingType;
  };

const PanelHeader = ({ children }: { children: ReactNode }) => {
  return <span className="uppercase">{children}</span>;
};

export const UpsertClientForm = () => {
  const { search } = useLocation();
  const { user } = useGlobalState();
  const params = new URLSearchParams(search);
  const clientId = params.get('clientId');
  const spouseId = params.get('spouseId');
  const { t } = useFinologyTranslation();
  const parsedClientId = clientId ? Number.parseInt(clientId) : null;
  const parsedSpouseId = spouseId ? Number.parseInt(spouseId) : null;
  const [spouse, setSpouse] = useState<{ firstName: string; id: number | undefined } | undefined>();

  const [initialValues, setInitialValues] = useState<FormValues | undefined>();
  const { scrollIntoId: formErrors, setScrollIntoId: setFormErrors } =
    useScrollIntoView('form__field_');

  const [lazyClientQuery] = useGetEditClientFormDetailsLazyQuery();
  const [lazySpouseQuery] = useGetEditClientFormDetails_SpouseLazyQuery();
  const [upsertClient] = useFullUpsertClientMutation();

  const { closeTopFlyout } = useFlyoutContext();
  const { navigate: flyoutNavigate } = useFlyoutNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const load = async () => {
      if (parsedClientId) {
        const { data } = await lazyClientQuery({ variables: { clientId: parsedClientId } });

        if (!data || !data.client) return;

        const { client } = data;
        setInitialValues({
          id: client.id,

          firstName: client.firstName,
          lastName: client.lastName,
          middleName: client.middleName,
          title: client.title,

          email: client.email,
          homePhoneNumber: client.homePhoneNumber,
          cellPhoneNumber: client.cellPhoneNumber,

          dateOfBirth: client.dateOfBirth ? dayjs.utc(client.dateOfBirth) : null,
          occupation: client.occupation,
          profession: client.profession,

          streetAddress: client.streetAddress,
          streetUnitNumber: client.streetUnitNumber,

          city: client.city,
          stateCode: client.stateCode!,
          zipCode: client.zipCode,

          salary: client.salary,
          salaryIncreaseRate: client.salaryIncreaseRate,
          stateTaxRate: STATE_TAX_RATE.find((x) => x.id === client.stateCode)?.id,

          investmentReturn: client.investmentReturn,
          federalLoanMonthlyPayment: client.federalLoanMonthlyPayment,

          taxFilingType: client.taxFilingType!,
          maritalStatus: client.maritalStatus!,
          spouse: client.spouse?.id,

          firstChildBirthYear: client.firstChildBirthYear,
          secondChildBirthYear: client.secondChildBirthYear,
          thirdChildBirthYear: client.thirdChildBirthYear,
          fourthChildBirthYear: client.fourthChildBirthYear,
          fifthChildBirthYear: client.fifthChildBirthYear,
          sixthChildBirthYear: client.sixthChildBirthYear,
          seventhChildBirthYear: client.seventhChildBirthYear,
          eighthChildBirthYear: client.eighthChildBirthYear,
          ninthChildBirthYear: client.ninthChildBirthYear,
          tenthChildBirthYear: client.tenthChildBirthYear,

          otherDependentsNumber: client.otherDependentsNumber,
          notes: client.notes,
          dependentsNumber: client.dependentsNumber,
          annualSalaryDeduction: client.annualSalaryDeduction,
          isSelfEmployed: client.isSelfEmployed,
          isItemizedDeductionDisabled: client.isItemizedDeductionDisabled,
          isThirdPartyAccessEnabled: client.isThirdPartyAccessEnabled,
        });
      } else if (parsedSpouseId) {
        const { data } = await lazySpouseQuery({ variables: { spouseId: parsedSpouseId } });

        if (!data || !data.client) return;

        setInitialValues({
          firstName: '',
          lastName: '',
          maritalStatus: MaritalStatus.Married,
          taxFilingType: TaxFilingType.MarriedFilingJointly,
          stateCode: 'CA',
          spouse: parsedSpouseId,
          isItemizedDeductionDisabled: false,
          isThirdPartyAccessEnabled: false,
        });
      } else {
        setInitialValues({
          firstName: '',
          lastName: '',
          stateCode: 'CA',
          spouse: null,
          isItemizedDeductionDisabled: false,
          isThirdPartyAccessEnabled: false,
        });
      }
    };

    load();
  }, [clientId]);

  if (!initialValues) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={{
        firstName: 'Required',
        lastName: 'Required',

        maritalStatus: 'Required',
        taxFilingType: 'Required',
      }}
      enableReinitialize
      onSubmit={async (v, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        const input: ClientFullUpsertInput = {
          id: v.id,

          firstName: v.firstName,
          lastName: v.lastName,
          middleName: v.middleName,
          title: v.title,

          email: v.email,
          homePhoneNumber: v.homePhoneNumber,
          cellPhoneNumber: v.cellPhoneNumber,

          dateOfBirth: v.dateOfBirth,
          occupation: v.occupation,
          profession: v.profession,

          streetAddress: v.streetAddress,
          streetUnitNumber: v.streetUnitNumber,

          city: v.city,
          stateCode: v.stateCode,
          zipCode: v.zipCode,

          salary: v.salary,
          salaryIncreaseRate: v.salaryIncreaseRate,
          stateTaxRate: STATE_TAX_RATE.find((x) => x.id === v.stateTaxRate)?.value,

          investmentReturn: v.investmentReturn,
          loanStartDate: v.loanStartDate,
          federalLoanMonthlyPayment: v.federalLoanMonthlyPayment,

          didIncomeSignificantlyChangeSinceLastFiling:
            v.didIncomeSignificantlyChangeSinceLastFiling,

          didClientFileFederalIncomeTaxInTheLastTwoYears:
            v.didClientFileFederalIncomeTaxInTheLastTwoYears,

          didSpouseIncomeSignificantlyChangeSinceLastFiling:
            v.didSpouseIncomeSignificantlyChangeSinceLastFiling,

          isRecentTaxFilingFiledJointlyWithSpouse: v.isRecentTaxFilingFiledJointlyWithSpouse,
          hasSchoolClosedBeforeReceivingDegree: v.hasSchoolClosedBeforeReceivingDegree,
          isTotallyAndPermanentlyDisabled: v.isTotallyAndPermanentlyDisabled,
          areLoansResultOfIdentityTheft: v.areLoansResultOfIdentityTheft,

          isEmployed: v.isEmployed,
          employerName: v.employerName,
          organizationType: v.organizationType,
          organizationName: v.organizationName,
          federalEmployerIdentificationNumber: v.federalEmployerIdentificationNumber,
          employerAddress: v.employerAddress,
          employerWebsite: v.employerWebsite,
          employmentStartDate: v.employmentStartDate,
          employmentEndDate: v.employmentEndDate,
          employmentStatus: v.employmentStatus,
          workHoursPerWeek: v.workHoursPerWeek,
          employerPurposes: v.employerPurposes,

          taxFilingType: v.taxFilingType!,
          maritalStatus: v.maritalStatus!,
          spouseId: v.spouse,

          firstChildBirthYear: v.firstChildBirthYear,
          secondChildBirthYear: v.secondChildBirthYear,
          thirdChildBirthYear: v.thirdChildBirthYear,
          fourthChildBirthYear: v.fourthChildBirthYear,
          fifthChildBirthYear: v.fifthChildBirthYear,
          sixthChildBirthYear: v.sixthChildBirthYear,
          seventhChildBirthYear: v.seventhChildBirthYear,
          eighthChildBirthYear: v.eighthChildBirthYear,
          ninthChildBirthYear: v.ninthChildBirthYear,
          tenthChildBirthYear: v.tenthChildBirthYear,

          dependentsNumber: v.dependentsNumber,
          otherDependentsNumber: v.otherDependentsNumber,
          notes: v.notes,
          isPslfActive: v.isPslfActive,
          certificationDate: v.certificationDate,
          annualSalaryDeduction: v.annualSalaryDeduction,
          isSelfEmployed: v.isSelfEmployed,
          isItemizedDeductionDisabled: v.isItemizedDeductionDisabled,
          isThirdPartyAccessEnabled: v.isThirdPartyAccessEnabled,
        };

        await upsertClient({
          variables: { input: input },
          refetchQueries: ['GetClients', 'GetClientDashboard', 'GetClientChecklist'],
        });

        if (clientId)
          notification.success({
            message: `${t('notifications.client_updated')}`,
            placement: 'bottom-center',
          });
        else
          notification.success({
            message: `${t('notifications.client_created')}`,
            placement: 'bottom-center',
          });

        closeTopFlyout();
      }}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};
        if (!values.firstName) {
          errors.firstName = 'Required';
        }
        if (!values.lastName) {
          errors.lastName = 'Required';
        }

        if (!values.maritalStatus) {
          errors.maritalStatus = 'Required';
        }
        if (!values.taxFilingType) {
          errors.taxFilingType = 'Required';
        }

        if (values.firstName != undefined && values.firstName.length > 100) {
          errors.firstName = 'First Name must be less than 100 characters';
        }

        if (values.lastName != undefined && values.lastName.length > 100) {
          errors.lastName = 'Last Name must be less than 100 characters';
        }

        const phoneRegExp = /^(\(\d{3}\)\s?|\d{3}[-\s]?)\d{3}[-\s]?\d{4}$/;

        if (values.homePhoneNumber && !phoneRegExp.test(values.homePhoneNumber)) {
          errors.homePhoneNumber = 'Home Phone number is not valid';
        }
        if (values.cellPhoneNumber && !phoneRegExp.test(values.cellPhoneNumber)) {
          errors.cellPhoneNumber = 'Cell Phone number is not valid';
        }

        if (values.salaryIncreaseRate === 0) {
          errors.salaryIncreaseRate = 'Annual Salary % Increase cannot be 0';
        }

        return errors;
      }}
    >
      {({ values, isSubmitting, errors, initialErrors, setFieldValue }) => {
        const flyoutHeaderLabel = isClient(user) ? 'My Details' : `Edit ${t('borrower.uppercase')}`;

        return (
          <>
            <FormErrorNotification />
            <FlyoutHeader
              primaryButton={{
                form: 'upsert-client-form',
                children: 'Save',
                disabled: isSubmitting,
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                },
                onClick: () => {
                  setFormErrors(!errors ? Object.keys(initialErrors) : Object.keys(errors));
                },
              }}
              secondaryButton={{
                onClick: closeTopFlyout,
                children: 'Cancel',
              }}
              label={clientId ? flyoutHeaderLabel : `New ${t('borrower.uppercase')}`}
            />

            <Form id="upsert-client-form" className="pb-16">
              <Accordion className="mt-4" collapseAll={false}>
                <Accordion.Panel>
                  <Accordion.Title>
                    <PanelHeader>Client Overview</PanelHeader>
                  </Accordion.Title>
                  <Accordion.Content>
                    <div className="grid grid-cols-4 gap-x-2 gap-y-4 items-center ">
                      <TextInputField name="firstName" placeholder="First Name*" />
                      <TextInputField name="lastName" placeholder="Last Name*" />
                      <TextInputField name="middleName" placeholder="Middle Name" />
                      <SelectField
                        name="stateCode"
                        label="State"
                        allowClear={false}
                        placeholder="Select"
                      >
                        {STATE_TAX_RATE.map((stateTaxRate) => (
                          <option key={stateTaxRate.id} value={stateTaxRate.id}>
                            {stateTaxRate.label}
                          </option>
                        ))}
                      </SelectField>
                      <RadioGroupField
                        name="isItemizedDeductionDisabled"
                        label="Income type"
                        className="col-span-1 mt-4"
                        options={[
                          { value: false, label: 'Annual Salary' },
                          { value: true, label: 'AGI' },
                        ]}
                      />
                      <CurrencyField name="salary" placeholder="AGI" sizing="md" />
                      <PercentageField
                        name="salaryIncreaseRate"
                        placeholder="Salary Increase Rate"
                        sizing="md"
                      />
                      <PercentageField
                        name="investmentReturn"
                        placeholder="Investment Return"
                        sizing="md"
                      />
                      <SelectField
                        name="stateTaxRate"
                        label="State Tax Rate"
                        allowClear={false}
                        placeholder="Select"
                      >
                        {STATE_TAX_RATE.map((rate) => (
                          <option key={rate.id} value={rate.id}>
                            {rate.label}
                          </option>
                        ))}
                      </SelectField>
                      <CurrencyField
                        name="itemizedDeductions"
                        placeholder="Itemized Deductions"
                        disabled={values.isItemizedDeductionDisabled?.toString() == 'true'}
                        sizing="md"
                      />

                      <CurrencyField
                        name="annualSalaryDeduction"
                        placeholder="Retirement Contribution "
                        sizing="md"
                      />
                      <SwitchField name="isSelfEmployed" label="Self Employed" />
                      <SwitchField
                        name="isThirdPartyAccessEnabled"
                        label="Enable Third Party Access (Invent.us)"
                        className="col-span-2"
                      />
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion className="mt-4" collapseAll={false}>
                <Accordion.Panel>
                  <Accordion.Title>
                    <PanelHeader>Contact</PanelHeader>
                  </Accordion.Title>
                  <Accordion.Content>
                    <div className="grid grid-cols-6 gap-x-2 gap-y-4">
                      <TextInputField
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="col-span-2"
                      />
                      <TextInputField
                        name="homePhoneNumber"
                        placeholder="Home Phone"
                        className="col-span-2"
                      />
                      <TextInputField
                        name="cellPhoneNumber"
                        placeholder="Cell Phone"
                        className="col-span-2"
                      />
                      <DateField
                        name="dateOfBirth"
                        placeholder="Date of Birth"
                        sizing="md"
                        className="col-span-2"
                      />
                      <SelectField
                        name="title"
                        label="Title"
                        className="col-span-2"
                        placeholder="Select"
                      >
                        <option value={Title.Dr}>Dr</option>
                        <option value={Title.Miss}>Miss</option>
                        <option value={Title.Mr}>Mr</option>
                        <option value={Title.Mrs}>Mrs</option>
                        <option value={Title.Ms}>Ms</option>
                      </SelectField>
                      <ProfessionSelectField
                        name="occupation"
                        label="Occupation"
                        className="col-span-2"
                      />

                      <TextInputField name="city" placeholder="City" className="col-span-2" />
                      <TextInputField
                        name="streetAddress"
                        placeholder="Address"
                        className="col-span-2"
                      />
                      <TextInputField
                        name="streetUnitNumber"
                        placeholder="Unit"
                        className="col-span-1"
                      />
                      <TextInputField
                        name="zipCode"
                        placeholder="Zip Code"
                        className="col-span-1"
                      />
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion className="mt-4" collapseAll={false}>
                <Accordion.Panel>
                  <Accordion.Title>
                    <PanelHeader>Family</PanelHeader>
                  </Accordion.Title>
                  <Accordion.Content>
                    <div className="grid grid-cols-10 gap-y-2 gap-x-2 mt-4 items-center">
                      <SelectField
                        allowClear={false}
                        className="col-span-3"
                        name="maritalStatus"
                        label="Marital Status*"
                        placeholder="Select"
                      >
                        <option value={MaritalStatus.Divorced}>Divorced </option>
                        <option value={MaritalStatus.LifePartner}>Life Partner </option>
                        <option value={MaritalStatus.Married}>Married </option>
                        <option value={MaritalStatus.Separated}>Separated </option>
                        <option value={MaritalStatus.Single}>Single </option>
                        <option value={MaritalStatus.Widowed}>Widowed </option>
                      </SelectField>

                      <SelectField
                        allowClear={false}
                        className="col-span-3"
                        name="taxFilingType"
                        label="Tax Filing Type*"
                        placeholder="Select"
                      >
                        <option value={TaxFilingType.HeadOfHousehold}>Head Of Household </option>
                        <option value={TaxFilingType.MarriedFilingJointly}>
                          Married Filing Jointly{' '}
                        </option>
                        <option value={TaxFilingType.MarriedFilingSeparately}>
                          Married Filing Separately{' '}
                        </option>
                        <option value={TaxFilingType.Single}>Single </option>
                      </SelectField>

                      {spouse ? (
                        <div className="ml-1 col-span-4 text-green-600 font-medium flex items-center gap-2">
                          <HiCheckCircle className="w-8 h-8" />
                          <span>Spouse {spouse?.firstName} has been successfully added</span>
                        </div>
                      ) : null}

                      {isClient(user) || spouse ? null : (
                        <>
                          <ClientSelectorField
                            className="col-span-3"
                            name="spouse"
                            placeholder="Spouse"
                            selectPlaceholder={'Select Spouse'}
                            idToExclude={parsedClientId}
                            onValueChanged={async (selectedId) => {
                              if (selectedId) {
                                if (values.maritalStatus == MaritalStatus.Single)
                                  setFieldValue('maritalStatus', MaritalStatus.Married);
                              } else {
                                setFieldValue('maritalStatus', MaritalStatus.Single);
                              }
                            }}
                          />
                          <>
                            <Button
                              color="secondaryLight"
                              className="max-h-12 col-span-1"
                              onClick={() => setIsModalOpen(true)}
                            >
                              Add
                            </Button>
                            <UploadNsldsFileModal
                              isOpen={isModalOpen}
                              onClose={(value) => {
                                if (value) {
                                  setSpouse({ firstName: value.firstName, id: value.id });
                                  setFieldValue('spouse', value.id);
                                }
                                setIsModalOpen(false);
                              }}
                            />
                          </>
                        </>
                      )}
                    </div>

                    <div className="grid grid-cols-3 gap-y-2 gap-x-2 col-start-1 col-span-6">
                      <SelectField
                        type="number"
                        className="col-span-1"
                        name="dependentsNumber"
                        label="Number of Children"
                        placeholder="Select"
                      >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                      </SelectField>

                      <SelectField
                        type="number"
                        className="col-span-1"
                        name="otherDependentsNumber"
                        label="Other Qualifying Dependents"
                        placeholder="Select"
                        size="md"
                      >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                      </SelectField>

                      <FutureChildrenInput className="col-span-1" />
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion className="mt-4" collapseAll={false}>
                <Accordion.Panel>
                  <Accordion.Title>
                    <PanelHeader>Versions</PanelHeader>
                  </Accordion.Title>
                  <Accordion.Content>
                    <Button
                      color="secondaryLight"
                      onClick={() =>
                        flyoutNavigate('client-federal-loan-versions', `clientId=${parsedClientId}`)
                      }
                    >
                      Go to Versions
                    </Button>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion className="mt-4" collapseAll={false}>
                <Accordion.Panel>
                  <Accordion.Title>
                    <PanelHeader>Other</PanelHeader>
                  </Accordion.Title>
                  <Accordion.Content>
                    <TextAreaField
                      placeholder="Notes"
                      name="notes"
                      className="w-full"
                      maxLength={1000}
                    />
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <div className="flex justify-end mt-4">
                <Button form="upsert-client-form" disabled={isSubmitting} type="submit">
                  Save
                </Button>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export const UpsertClientFlyout = () => {
  return (
    <Flyout
      id={`upsert-client`}
      requiredParams={['clientId', 'spouseId']}
      content={<UpsertClientForm />}
      size="medium"
    ></Flyout>
  );
};
