import React from 'react';

const withSkeleton = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const ComponentWithSkeleton = (props: P & { isLoading: boolean }) => {
    if (props.isLoading) {
      return (
        <div className="bg-white rounded-2xl shadow-md overflow-hidden">
          <div className="h-4 bg-gray-300 rounded-t-2xl animate-pulse" />
          <div className="p-6">
            <div className="h-4 bg-gray-300 rounded w-1/2 mb-6 animate-pulse" />
            <div className="flex justify-start w-[275px]">
              <div className="pr-4">
                <div className="h-4 bg-gray-300 rounded w-16 mb-2 animate-pulse" />
                <div className="h-6 bg-gray-300 rounded w-24 animate-pulse" />
              </div>
              <div className="border-l pl-8">
                <div className="h-4 bg-gray-300 rounded w-16 mb-2 animate-pulse" />
                <div className="h-6 bg-gray-300 rounded w-24 animate-pulse" />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <WrappedComponent {...props} />;
  };

  ComponentWithSkeleton.displayName = `withSkeleton(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return ComponentWithSkeleton;
};

export default withSkeleton;
