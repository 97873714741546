import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { range, times } from '../../util/array.utilts';

import SelectField from './SelectField';

const childPropertyNames = [
  'firstChildBirthYear',
  'secondChildBirthYear',
  'thirdChildBirthYear',
  'fourthChildBirthYear',
  'fifthChildBirthYear',
  'sixthChildBirthYear',
  'seventhChildBirthYear',
  'eighthChildBirthYear',
  'ninthChildBirthYear',
  'tenthChildBirthYear',
];

type Props = { className: string };

const FutureChildrenInput = ({ className }: Props) => {
  const form = useFormikContext<any>();

  const futureChildrenNumber = form.values['futureChildrenNumber'];

  useEffect(() => {
    const futureChildrenNumber = childPropertyNames.reduce(
      (accumulator, propertyName) => (form.values[propertyName] ? accumulator + 1 : accumulator),
      0
    );

    form.setFieldValue(
      'futureChildrenNumber',
      futureChildrenNumber == 0 ? undefined : futureChildrenNumber
    );
  }, []);

  useEffect(() => {
    if (futureChildrenNumber) {
      for (let i = futureChildrenNumber || 0; i < childPropertyNames.length; i++) {
        form.setFieldValue(childPropertyNames[i], null);
      }
    }
  }, [futureChildrenNumber]);

  const constructChildYearSelectors = (futureChildrenNumber: number) => {
    const options = range(2022, 2045).map((year) => (
      <option key={`${year}`} value={year}>
        {year}
      </option>
    ));

    return times(futureChildrenNumber, (n) => {
      return (
        <SelectField
          key={n}
          type="number"
          className={className}
          name={childPropertyNames[n]}
          placeholder={`Select`}
          label={`Child #${n + 1}`}
        >
          {options}
        </SelectField>
      );
    });
  };

  return (
    <>
      <SelectField
        className={className}
        name="futureChildrenNumber"
        label="Number of Planned Children"
        type="number"
        placeholder="Select"
        size="md"
      >
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
        <option value={6}>6</option>
        <option value={7}>7</option>
        <option value={8}>8</option>
        <option value={9}>9</option>
        <option value={10}>10</option>
      </SelectField>

      {constructChildYearSelectors(futureChildrenNumber)}
    </>
  );
};

export default FutureChildrenInput;
