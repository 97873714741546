import React from 'react';

interface FinoCardProps {
  title: string;
  children: React.ReactNode;
}

const FinoCard: React.FC<FinoCardProps> = ({ title, children }) => {
  return (
    <div className="bg-white rounded-lg shadow-sm border h-24">
      <div className="bg-gray-100 rounded-t-lg flex items-center justify-center py-3">
        <h2 className="text-xs font-semibold text-gray-500 uppercase">{title}</h2>
      </div>
      <div className="flex items-center justify-center h-[calc(100%-42px)]">
        <div className="text-xl font-semibold text-primary-900 text-center">{children}</div>
      </div>
    </div>
  );
};

export default FinoCard;
