import dayjs from 'dayjs';
import { Form, Formik, FormikErrors } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Flyout, useFlyoutContext } from '../../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../../components/flyout/FlyoutHeader';
import { FormErrorNotification } from '../../../../components/forms/FormErrorNotification';
import {
  PrivateLoanType,
  PrivateLoanView,
  useGetPrivateLoanDetailsLazyQuery,
  useUpsertPrivateLoanMutation,
} from '../../../../graphql/generated';
import { notification } from '../../../../util/notification.utils';
import { CheckboxField } from '../../../formComponents/CheckboxField';
import { CurrencyField } from '../../../formComponents/CurrencyField';
import { DateField } from '../../../formComponents/DateField';
import { TextInputField } from '../../../formComponents/InputField';
import { PercentageField } from '../../../formComponents/PercentageField';
import SelectField from '../../../formComponents/SelectField';

type FormValues = Partial<PrivateLoanView>;

const FlyoutContent = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const loanId = params.get('loanId');
  const clientId = Number.parseInt(params.get('clientId')!);

  const parsedLoanId = loanId ? Number.parseInt(loanId) : null;

  const [getPrivateLoan] = useGetPrivateLoanDetailsLazyQuery();

  const [upsertPrivateLoan] = useUpsertPrivateLoanMutation();

  const [initialValues, setInitialValues] = useState<FormValues>({
    clientId: clientId,
  });

  const { closeTopFlyout } = useFlyoutContext();

  useEffect(() => {
    async function initialize() {
      if (parsedLoanId == null) return;

      const { data } = await getPrivateLoan({ variables: { loanId: parsedLoanId } });

      if (!data?.privateLoan) return;

      const { privateLoan } = data;

      setInitialValues({
        amount: privateLoan.amount,
        lender: privateLoan.lender,
        clientId: privateLoan.clientId,
        extraPayout: privateLoan.extraPayout,
        id: privateLoan.id,
        interestRate: (privateLoan.interestRate * 100).toFixed(2),
        loanName: privateLoan.loanName,
        monthlyPayment: privateLoan.monthlyPayment,
        monthlyPrepayment: privateLoan.monthlyPrepayment,
        startDate: privateLoan.startDate ? dayjs.utc(privateLoan.startDate) : null,
        termInYears: privateLoan.termInYears,
        termInMonths: privateLoan.termInMonths,
        isClosed: privateLoan.isClosed,
        type: privateLoan.type,
      });
    }

    initialize();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validate={(values) => {
        const errors: FormikErrors<{
          loanName: string;
          type: string;
          monthlyPayment?: number;
          amount?: number;
          extraPayout?: number;
        }> = {};

        if (!values.loanName) {
          errors.loanName = 'Required';
        }

        if (!values.type) {
          errors.type = 'Required';
        }

        if (values.loanName != undefined && values.loanName.length > 120) {
          errors.loanName = 'Loan Name must be less than 120 characters';
        }
        if (!values.amount && values.monthlyPayment) {
          errors.amount = 'Monthly payment should not be more than the loan amount';
        }
        if (values.monthlyPayment && values.monthlyPayment > values.amount) {
          errors.monthlyPayment = 'Monthly payment should not be more than the loan amount';
        }

        if (!values.isClosed && !values.monthlyPayment) {
          errors.monthlyPayment = 'Monthly payment is required';
        }

        if (values.extraPayout && values.extraPayout > 1000000) {
          errors.extraPayout = 'The extra payout must be less than $1,000,000.';
        }

        return errors;
      }}
      onSubmit={async (v, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        await upsertPrivateLoan({
          variables: {
            input: {
              amount: v.amount,
              lender: v.lender,
              clientId: v.clientId!,
              extraPayout: v.extraPayout,
              id: v.id,
              interestRate: v.interestRate / 100,
              loanName: v.loanName!,
              monthlyPayment: v.monthlyPayment,
              monthlyPrepayment: v.monthlyPrepayment,
              startDate: v.startDate,
              termInYears: v.termInYears,
              termInMonths: v.termInMonths,
              isClosed: v.isClosed || false,
              type: v.type!,
            },
          },
          refetchQueries: ['GetPrivateLoans', 'GetClientDashboard', 'GetClientChecklist'],
        });

        notification.success({
          placement: 'bottom-center',
          message: v.id ? 'Private loan has been saved' : 'Private loan has been created',
        });

        closeTopFlyout();
      }}
    >
      {({ isSubmitting }) => {
        return (
          <>
            <FormErrorNotification />
            <FlyoutHeader
              primaryButton={{
                form: 'upsert-private-loan-form',
                children: 'Save',
                disabled: isSubmitting,
              }}
              secondaryButton={{
                onClick: closeTopFlyout,
                children: 'Cancel',
              }}
              label={loanId ? 'Edit Private Loan' : 'Add Private Loan'}
            />

            <Form id={'upsert-private-loan-form'}>
              <TextInputField sizing="md" name="loanName" placeholder="Loan Name"></TextInputField>
              <SelectField name="type" label="Loan Type" allowClear={false}>
                <option value={PrivateLoanType.StudentLoan}> Student Loan</option>
                <option value={PrivateLoanType.PersonalLoan}> Personal Loan</option>
                <option value={PrivateLoanType.Mortgage}> Mortgage</option>
                <option value={PrivateLoanType.Auto}> Auto</option>
                <option value={PrivateLoanType.CreditCard}> Credit Card</option>
                <option value={PrivateLoanType.Other}> Other</option>
              </SelectField>
              <TextInputField name="lender" placeholder="Lender"></TextInputField>
              <PercentageField
                sizing="md"
                name="interestRate"
                placeholder="Interest Rate"
              ></PercentageField>
              <CurrencyField sizing="md" name="amount" placeholder="Amount"></CurrencyField>
              <div className="grid grid-cols-2 gap-4">
                <TextInputField
                  sizing="md"
                  type="number"
                  name="termInYears"
                  placeholder="Term (Years)"
                ></TextInputField>
                <TextInputField
                  sizing="md"
                  type="number"
                  name="termInMonths"
                  placeholder="Term (Months)"
                ></TextInputField>
              </div>

              <CheckboxField
                className="mb-6 text-sm font-normal"
                name="isClosed"
                label="Loan Is Closed"
              />

              <CurrencyField
                sizing="md"
                name="monthlyPayment"
                placeholder="Monthly Payment"
              ></CurrencyField>
              {/* TODO: Entirely remove */}
              {/* <CurrencyField
                  name="monthlyPrepayment"
                  placeholder="Monthly Prepayment"
                ></CurrencyField> */}
              <CurrencyField
                sizing="md"
                name="extraPayout"
                placeholder="Extra Payment"
              ></CurrencyField>
              <DateField sizing="md" name="startDate" placeholder="Start Date"></DateField>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export const UpsertPrivateLoanFlyout = () => {
  return (
    <Flyout
      id={`upsert-private-loan`}
      requiredParams={['loanId', 'clientId']}
      size={'small'}
      content={<FlyoutContent />}
    ></Flyout>
  );
};
