import React from 'react';

import { DashboardLoanDetails } from './DashboardLoanDetails';
import { RecentClients } from './RecentClients';

export const Dashboard: React.FC = () => {
  return (
    <div className="container px-4 py-8">
      <DashboardLoanDetails />
      <RecentClients />
    </div>
  );
};
