import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import { useFlyoutPortal } from '../../hooks/useFlyoutPortal';

import { getBackUrl, getSearchParams } from './Flyout.utils';

export const FlyoutContext = createContext<{ closeTopFlyout: () => void }>({
  closeTopFlyout: () => {},
});

export const useFlyoutContext = () => {
  return useContext(FlyoutContext);
};

const LAYER_WIDTH = ['w-[80%]', 'w-[82%]', 'w-[84%]', 'w-[86%]'];
interface Props {
  content: ReactNode;
  onClose?: () => void;
  id: string;
  isOpen?: boolean;
  size?: 'small' | 'medium' | 'large';
  requiredParams?: string[];
}

const animateFlyout = (isOpening: boolean, index: number) => {
  if (document.getElementById(`flyout-container-${index}`)) {
    document.getElementById(`flyout-container-${index}`)!.style.transform = `translateX(${
      isOpening ? 0 : 100
    }%)`;
  }
};

export const Flyout = ({ onClose, isOpen, content, id, size, requiredParams }: Props) => {
  const { search, state } = useLocation();
  const navigate = useNavigate();

  const params = getSearchParams(search);

  const getLayerIndex = () => {
    return params.findIndex((x) => x === id);
  };

  const layerIndex = getLayerIndex();

  useEffect(() => {
    const isActive = isFlyoutActive() || isOpen;

    if (isActive) {
      document.body.style.overflowY = 'hidden';
      setTimeout(() => {
        animateFlyout(true, layerIndex);
      });
    }

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [isOpen, search, id]);

  const isFlyoutActive = () => {
    if (params.length) return params.find((x) => x === id);
  };

  const onCloseClick = () => {
    animateFlyout(false, layerIndex);

    setTimeout(() => {
      if (onClose) onClose();
      navigate(getBackUrl(search, params[0]), {
        state: state,
      });
    }, 500);
  };

  const isActive = isFlyoutActive() || isOpen;

  const target = useFlyoutPortal();

  const flyoutWidth = LAYER_WIDTH[params.length - layerIndex];

  const width = !size || size == 'large' ? flyoutWidth : size == 'small' ? 'w-[520px]' : 'w-[50%]';
  const padding = size == 'small' ? 'px-4' : 'px-10';
  const transitionDuration = !size || size == 'large' ? '500ms' : '350ms';

  const flyoutOpacity = layerIndex > 0 ? { opacity: '0.35' } : { opacity: '0.65' };

  const body = () => (
    <div
      style={{
        display: isActive ? 'block' : 'none',
      }}
    >
      <div
        style={{
          zIndex: 32 + layerIndex * 2,
          bottom: 0,
          content: '',
          left: 0,
          position: 'fixed',
          right: 0,
          top: '0px',
          backgroundColor: 'black',
          ...flyoutOpacity,
        }}
        onClick={onCloseClick}
      />

      <div
        className={`fixed bg-white top-0 right-0 h-full min-h-full  ${width}`}
        id={`flyout-container-${layerIndex}`}
        data-required-params={requiredParams?.toString()}
        style={{
          zIndex: 32 + layerIndex * 2,
          overflowY: 'auto',
          transform: 'translateX(100%)',
          transition: `all ${transitionDuration} ease-in`,
          transitionProperty: 'transform, width',
        }}
      >
        <div className={padding}>{content}</div>
      </div>
    </div>
  );

  return isActive ? createPortal(body(), target) : null;
};
