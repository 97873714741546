import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';
import { FaCreditCard } from 'react-icons/fa';

import FinoCard from '../../../../components/FinoCard/FinoCard';
import { FlyoutLink } from '../../../../components/links/FlyoutLink';
import { FinologyTable } from '../../../../components/table/FinologyTable';
import {
  CreditCardLoanView,
  CreditCardLoanViewSortInput,
  InputMaybe,
  PrivateLoanView,
  SortEnumType,
  useCreditCardAggregationsQuery,
  useDeletePrivateLoanMutation,
  useGetCreditCardLoansLazyQuery,
} from '../../../../graphql/generated';
import { useFlyoutNavigate } from '../../../../hooks/useFlyoutNavigate';
import { useTableSortBy } from '../../../../hooks/useTableSortBy';
import { UploadCreditCardFileModal } from '../../../../modals/UploadCreditCardFileModal';
import { toDollars } from '../../../../util/currency.formatter';
import { notification } from '../../../../util/notification.utils';
import { toPercentage } from '../../../../util/number.formatter';

import type { ColumnsType } from '../../../../components/table/FinologyTable';

const DeletePrivateLoanModal = ({
  loanToDelete,
  closeModal,
}: {
  loanToDelete?: PrivateLoanView;
  closeModal: () => void;
}) => {
  const [deleteLoan] = useDeletePrivateLoanMutation();
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);

  return (
    <Modal show={loanToDelete !== undefined} onClose={closeModal}>
      <Modal.Header>Delete Private Loan</Modal.Header>
      <Modal.Body>
        <p>Proceed with deleting of {loanToDelete?.loanName}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button color="light" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={disableDeleteButton}
          onClick={async () => {
            setDisableDeleteButton(true);

            await deleteLoan({
              variables: {
                id: loanToDelete!.id,
              },
              refetchQueries: ['GetPrivateLoans', 'GetClientDashboard', 'GetClientChecklist'],
            });

            setDisableDeleteButton(false);

            closeModal();

            notification.success({
              message: `${loanToDelete?.loanName} was successfully deleted`,
              placement: 'bottom-center',
            });
          }}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const CreditCardLoansGrid = ({ clientId }: { clientId: number }) => {
  const [privateLoanToDelete, setPrivateLoanToDelete] = useState<PrivateLoanView>();

  const {
    sortBy: sortByCreditCard,
    prepareSortColumns: prepareSortColumnsCreditCard,
    triggerRefetch: triggerRefetchCreditCard,
  } = useTableSortBy<any>({
    outstandingBalance: SortEnumType.Desc,
  });

  const [getCreditCardLoans, getCreditCardLoansQuery] = useGetCreditCardLoansLazyQuery();
  const creditCardAggregations = useCreditCardAggregationsQuery({
    variables: { clientId: clientId },
  });

  const creditCardColumns: ColumnsType<any> = [
    {
      title: 'BANK NAME',
      dataIndex: 'bankName',
      key: 'bankName',
      render: (text, record) => (
        <FlyoutLink flyoutId="upsert-credit-card" params={`creditCardId=${record.id}`}>
          {text}
        </FlyoutLink>
      ),
    },
    {
      dataIndex: 'outstandingBalance',
      key: 'outstandingBalance',
      render: (text) => toDollars(text) || '-',
      ...prepareSortColumnsCreditCard(
        'outstandingBalance',
        'OUTSTANDING BALANCE',
        triggerRefetchCreditCard
      ),
    },

    {
      dataIndex: 'balanceTransferApr',
      key: 'balanceTransferApr',
      render: (text) => toPercentage(text ? text / 100 : text) || '-',
      ...prepareSortColumnsCreditCard(
        'balanceTransferApr',
        'BALANCE APR',
        triggerRefetchCreditCard
      ),
    },
    {
      dataIndex: 'balanceTransferRate',
      key: 'balanceTransferRate',
      render: (text) => toPercentage(text ? text / 100 : text) || '-',
      ...prepareSortColumnsCreditCard('balanceTransferRate', 'RATE', triggerRefetchCreditCard),
    },
    {
      dataIndex: 'minimumPayments',
      key: 'minimumPayments',
      render: (text) => toDollars(text) || '-',
      ...prepareSortColumnsCreditCard(
        'minimumPayments',
        'MIN. MONTHLY PAYMENT',
        triggerRefetchCreditCard
      ),
    },
  ];

  async function loadCreditCards(
    skip?: number,
    take?: number,
    filterValue?: string,
    sortBy?: InputMaybe<CreditCardLoanViewSortInput | CreditCardLoanViewSortInput[]>
  ) {
    const { data } = await getCreditCardLoans({
      variables: {
        clientId: clientId,
        skip,
        take,
        sortBy,
      },
    });

    return {
      rows: data!.creditCardLoans!.items as CreditCardLoanView[],
      totalCount: data!.creditCardLoans!.totalCount,
    };
  }

  const EmptyState = () => {
    const { navigate } = useFlyoutNavigate();
    const [showAddModal, setShowAddModal] = useState(false);

    return (
      <div className="flex flex-col items-center justify-center py-16 px-4">
        {showAddModal && (
          <UploadCreditCardFileModal
            clientId={clientId}
            isOpen={showAddModal}
            onClose={() => setShowAddModal(false)}
          />
        )}
        <div className="text-gray-400 mb-4">
          <FaCreditCard className="text-gray-400" size={40} />
        </div>
        <h3 className="text-xl font-semibold text-gray-500 mb-2">No Credit Cards Added Yet</h3>
        <p className="text-gray-500 text-center mb-6">
          This table will be automatically populated after you add a credit card.
        </p>
        <div className="flex gap-3">
          <Button color="secondaryLight" onClick={() => setShowAddModal(true)}>
            Add Credit Card
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <DeletePrivateLoanModal
        loanToDelete={privateLoanToDelete}
        closeModal={() => setPrivateLoanToDelete(undefined)}
      />
      <div
        className={`grid grid-cols-3 gap-4 mb-8 ${
          !getCreditCardLoansQuery.data?.creditCardLoans?.totalCount ? 'opacity-50' : ''
        }`}
      >
        <FinoCard title="Total Outstanding Balance">
          {toDollars(creditCardAggregations.data?.creditCardAggregations?.outstandingBalance)}
        </FinoCard>
        <FinoCard title="Sum of Payment">
          {toDollars(creditCardAggregations.data?.creditCardAggregations?.totalPayments)}
        </FinoCard>
        <FinoCard title="Credit Card Utilization">
          {toPercentage(creditCardAggregations.data?.creditCardAggregations?.creditCardUtilization)}
        </FinoCard>
      </div>
      <div className="flex-1 gap-y-2">
        <FinologyTable
          emptyText={() => <EmptyState />}
          columns={creditCardColumns}
          dataSourcePromise={loadCreditCards}
          reloadDependency={getCreditCardLoansQuery.previousData}
          searchPlaceholder="Search for credit cards..."
          sortBy={sortByCreditCard}
          hideFooter={true}
          tableHeadingLabel="Credit Cards"
        />
      </div>
    </>
  );
};

export const CreditCardLoans = ({ clientId }: { clientId: number }) => {
  return (
    <div className="py-8">
      <CreditCardLoansGrid clientId={clientId} />
    </div>
  );
};
