import { Card } from 'flowbite-react';
import { useState } from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';

import { useGetClientChecklistQuery } from '../../graphql/generated';
import { FlyoutLink } from '../links/FlyoutLink';

export const Checklist = ({ clientId }: { clientId: number }) => {
  const navigate = useNavigate();
  const { data } = useGetClientChecklistQuery({
    variables: {
      clientId,
    },
  });

  const getDismissedItems = (): string[] => {
    const stored = localStorage.getItem(`dismissedChecklist_${clientId}`);
    return stored ? JSON.parse(stored) : [];
  };

  const [dismissed, setDismissed] = useState<string[]>(getDismissedItems());

  const dismissItem = (itemId: string) => {
    const updated = [...dismissed, itemId];
    localStorage.setItem(`dismissedChecklist_${clientId}`, JSON.stringify(updated));
    setDismissed(updated);
  };

  const checklistItems = [
    {
      id: 'hasUploadedNslds',
      text: 'Upload NSLDS file',
      completed: data?.clientChecklist?.hasUploadedNslds,
      flyoutId: 'upload-nslds',
      params: `clientId=${clientId}&redirect=true`,
    },
    {
      id: 'hasFederalLoan',
      text: 'Add federal loans',
      completed: data?.clientChecklist?.hasFederalLoan,
      flyoutId: 'upsert-federal-loan',
      params: `clientId=${clientId}`,
    },
    {
      id: 'hasSpouse',
      text: 'Add spouse',
      completed: data?.clientChecklist?.hasSpouse,
      flyoutId: 'pick-spouse',
      params: `clientId=${clientId}`,
    },
    {
      id: 'hasPrivateLoan',
      text: 'Add private loans',
      completed: data?.clientChecklist?.hasPrivateLoan,
      flyoutId: 'upsert-private-loan',
      params: `clientId=${clientId}`,
    },
    {
      id: 'hasCreditCard',
      text: 'Add credit cards',
      completed: data?.clientChecklist?.hasCreditCard,
      flyoutId: 'upsert-credit-card',
      params: `clientId=${clientId}`,
    },
    {
      id: 'hasScenario',
      text: 'Create scenario',
      completed: data?.clientChecklist?.hasScenario,
      redirect: `calculator`,
    },
    {
      id: 'hasReport',
      text: 'Create report',
      completed: data?.clientChecklist?.hasReport,
      redirect: `calculator`,
    },
  ];

  const incompleteItems = checklistItems.filter(
    (item) => !item.completed && !dismissed.includes(item.id)
  );

  return (
    <Card
      className="w-[311px] grow min-w-min"
      theme={{
        root: {
          children: 'p-0',
        },
      }}
    >
      <div className="px-6 py-4 border-b">
        <div className="text-base font-semibold">Checklist</div>
      </div>
      {incompleteItems.length > 0 ? (
        <ul className="max-h-[162px] overflow-y-auto">
          {incompleteItems.map((item) => (
            <li
              key={item.id}
              className="flex items-center px-2 py-4 space-x-3 border-b mx-4 h-[54px]"
            >
              <div
                className="cursor-pointer flex items-center justify-center w-4 h-6 text-gray-400 border-white rounded-full group"
                onClick={() => dismissItem(item.id)}
              >
                <CgClose size={20} className="group-hover:text-gray-200 transition-colors" />
              </div>
              {item.redirect ? (
                <div
                  onClick={() => navigate(item.redirect!)}
                  className="text-primary-900 text-xs font-medium cursor-pointer"
                >
                  {item.text}
                </div>
              ) : (
                <FlyoutLink
                  flyoutId={item.flyoutId!}
                  params={item.params!}
                  className="text-primary-900 text-xs font-medium"
                >
                  {item.text}
                </FlyoutLink>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <div className="flex flex-col items-center justify-center p-4 text-center">
          <div className="w-8 h-8 mb-2 text-emerald-400">
            <BsCheckCircleFill className="w-full h-full" />
          </div>
          <p className="text-green-800">All done! New notifications will appear here.</p>
        </div>
      )}
    </Card>
  );
};
