import { TaxFilingType } from './graphql/generated';

export const REGISTRATION_PROCESS_LOCAL_STORAGE_ID = 'finology-registration-process';

export const TAX_FILING_TYPES = {
  [TaxFilingType.HeadOfHousehold]: 'HOH',
  [TaxFilingType.MarriedFilingJointly]: 'MFJ',
  [TaxFilingType.MarriedFilingSeparately]: 'MFS',
  [TaxFilingType.Single]: 'Single',
} as const;
