import { Accordion, Button, Modal } from 'flowbite-react';
import { Formik, Field, Form, FieldArray, FormikErrors } from 'formik';
import { useState } from 'react';

import { PanelHeader } from '../components/Accordion/PanelHeader';
import { useFlyoutContext } from '../components/flyout/Flyout';
import { UploadField } from '../features/clients/flyouts/creditCards/UploadFile';
import { CurrencyField } from '../features/formComponents/CurrencyField';
import { DateField } from '../features/formComponents/DateField';
import { TextInputField } from '../features/formComponents/InputField';
import { PercentageField } from '../features/formComponents/PercentageField';
import SelectField from '../features/formComponents/SelectField';
import { useInsertCreditCardLoansMutation } from '../graphql/generated';
import { CreditCardLoanDtoInput } from '../graphql/generated';
import { notification } from '../util/notification.utils';

type FormValues = Partial<CreditCardLoanDtoInput>;

const MultipleLoansView = ({
  loans,
  clientId,
}: {
  loans: CreditCardLoanDtoInput[];
  clientId: number;
}) => {
  const [insertLoans] = useInsertCreditCardLoansMutation();
  const { closeTopFlyout } = useFlyoutContext();

  return (
    <div className="mt-6">
      <div className="flex justify-between mb-6">
        <div className="text-2xl mb-4">Confirm your loans</div>
        <Button type="submit" form="multiple-credit-card-loans-insert" color="primary" size="sm">
          Confirm
        </Button>
      </div>
      <Formik
        initialValues={{
          loans: loans,
        }}
        validate={(values) => {
          const errors: FormikErrors<any> = {};
          values.loans.forEach((loan, index) => {
            if (loan.last4Digits && loan.last4Digits.length !== 4) {
              errors[`loans[${index}].last4Digits`] = 'Exactly 4 characters';
            }

            if (loan.creditCardLimit && loan.outstandingBalance) {
              if (loan.creditCardLimit < loan.outstandingBalance) {
                errors[`loans[${index}].creditCardLimit`] = 'Should be greater than balance';
              }
            }

            if (loan.minimumPayments && loan.creditCardLimit) {
              if (loan.minimumPayments > loan.creditCardLimit) {
                errors[`loans[${index}].minimumPayments`] = 'Should be less than limit';
              }
            }
          });

          return errors;
        }}
        enableReinitialize={true}
        onSubmit={async (values) => {
          await insertLoans({
            variables: {
              input: {
                clientId: clientId,
                loansToInsert: values.loans,
              },
            },
            refetchQueries: [
              'GetCreditCardLoans',
              'GetClientDashboard',
              'CreditCardAggregations',
              'GetClientChecklist',
            ],
          });

          notification.success({
            message: 'Credit Card Loans Created Successfully',
            placement: 'bottom-center',
          });

          closeTopFlyout();
        }}
      >
        {({ values, initialValues }) => (
          <Form id="multiple-credit-card-loans-insert">
            <FieldArray name="loans">
              {({ remove }) =>
                values.loans.map((loan: FormValues, index: number) => (
                  <Accordion
                    key={initialValues.loans[index].bankName + index}
                    className="mt-4"
                    collapseAll={!!index}
                  >
                    <Accordion.Panel collapseAll={true}>
                      <Accordion.Title>
                        <PanelHeader>{initialValues.loans[index].bankName}</PanelHeader>
                      </Accordion.Title>
                      <Accordion.Content>
                        <div className="flex justify-end items-center">
                          <div className="flex gap-x-2">
                            <Button color="light" size="sm" onClick={() => remove(index)}>
                              Delete
                            </Button>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-2 gap-y-2 items-end">
                          <TextInputField
                            name={`loans[${index}].bankName`}
                            placeholder="Bank Name"
                            sizing="md"
                          />
                          <TextInputField
                            name={`loans[${index}].cardName`}
                            placeholder="Credit Card Name"
                            sizing="md"
                          />
                          <SelectField
                            name={`loans[${index}].type`}
                            size="md"
                            styles={{
                              control: {
                                padding: 2,
                              },
                            }}
                          >
                            <option value="REVOLVING">REVOLVING</option>
                            <option value="OPEN">OPEN</option>
                            <option value="CLOSED">CLOSED</option>
                          </SelectField>
                          <TextInputField
                            name={`loans[${index}].type`}
                            placeholder="Type"
                            sizing="md"
                          />
                          <TextInputField
                            name={`loans[${index}].last4Digits`}
                            placeholder="XXXX"
                            label="Last 4 Digits"
                            sizing="md"
                          />
                          <DateField
                            name={`loans[${index}].cardOpenDate`}
                            placeholder="mm/dd/yyyy"
                            label="Card Open Date"
                            sizing="md"
                          />
                          <DateField
                            name={`loans[${index}].cardCloseDate`}
                            placeholder="mm/dd/yyyy"
                            label="Card Close Date"
                            sizing="md"
                          />
                          <CurrencyField
                            name={`loans[${index}].outstandingBalance`}
                            placeholder="Outstanding Balance"
                            sizing="md"
                          />
                          <DateField
                            name={`loans[${index}].outstandingBalanceDate`}
                            placeholder="mm/dd/yyyy"
                            label="Outstanding Balance as of Date"
                            sizing="md"
                          />{' '}
                        </div>
                        <div className="grid grid-cols-3 gap-x-2 gap-y-2 mt-2 items-end">
                          <PercentageField
                            name={`loans[${index}].balanceTransferApr`}
                            placeholder="00.00"
                            label="Balance APR"
                            sizing="md"
                          />
                          <PercentageField
                            name={`loans[${index}].purchaseApr`}
                            placeholder="00.00"
                            label="Purchase APR"
                            sizing="md"
                          />
                          <PercentageField
                            name={`loans[${index}].cashAdvanceApr`}
                            placeholder="00.00"
                            label="Cash Advance APR"
                            sizing="md"
                          />
                          <PercentageField
                            name={`loans[${index}].balanceTransferRate`}
                            placeholder="00.00"
                            label="Balance Transfer Rate"
                            sizing="md"
                          />
                          <PercentageField
                            name={`loans[${index}].cashAdvanceRate`}
                            placeholder="00.00"
                            label="Cash Advance Rate"
                            sizing="md"
                          />
                          <CurrencyField
                            name={`loans[${index}].transactionFees`}
                            placeholder="0000.00"
                            label="Transaction Fees"
                            sizing="md"
                          />
                          <CurrencyField
                            name={`loans[${index}].penaltyFees`}
                            placeholder="0000.00"
                            label="Penalty Fees"
                            sizing="md"
                          />
                          <CurrencyField
                            name={`loans[${index}].creditCardLimit`}
                            placeholder="0000.00"
                            label="Credit Card Limit"
                            sizing="md"
                          />
                          <CurrencyField
                            name={`loans[${index}].minimumPayments`}
                            placeholder="0000.00"
                            label="Monthly Minimum Payment"
                            sizing="md"
                          />
                        </div>
                      </Accordion.Content>
                    </Accordion.Panel>
                  </Accordion>
                ))
              }
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const UploadCreditCardFileModal = ({
  isOpen = true,
  clientId,
  onClose,
}: {
  isOpen?: boolean;
  clientId: number;
  onClose: () => void;
}) => {
  const [insertLoans] = useInsertCreditCardLoansMutation();
  const [creditCardLoans, setCreditCardLoans] = useState<CreditCardLoanDtoInput[]>();

  const initialValues = {
    upload: null,
  };

  const onUpload = (response: CreditCardLoanDtoInput[]) => {
    const withClientIdResponse = response.map((r) => {
      return { ...r, clientId: clientId };
    });
    setCreditCardLoans(withClientIdResponse);
  };

  return (
    <Modal show={isOpen} className="bg-black bg-opacity-80" size={'lg'}>
      <div className="border border-b border-gray-200 p-4 rounded-t-lg">
        <div className="font-medium text-gray-900 text-lg ml-6">Upload Credit Card Report</div>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={async () => {
          if (creditCardLoans)
            await insertLoans({
              variables: {
                input: {
                  clientId: clientId,
                  loansToInsert: creditCardLoans,
                },
              },
              refetchQueries: [
                'GetCreditCardLoans',
                'GetClientDashboard',
                'CreditCardAggregations',
                'GetClientChecklist',
              ],
            });

          notification.success({
            message: 'Credit Card Loans Created Successfully',
            placement: 'bottom-center',
          });

          onClose();
        }}
      >
        {({ values }) => (
          <Form>
            <Modal.Body className="p-0">
              <div className="flex flex-col items-center justify-center gap-4 mb-4 p-4">
                <Field name="upload">
                  {({ field }: any) => (
                    <UploadField
                      {...field}
                      onUpload={(response) => {
                        onUpload(response);
                      }}
                    />
                  )}
                </Field>
              </div>
            </Modal.Body>
            <div className="w-full px-4 py-4 border-t border-gray-200">
              <div className="flex flex-row w-full gap-2 justify-end">
                <Button type="button" color="light" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!values.upload}>
                  Save
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
