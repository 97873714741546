import { Button } from 'flowbite-react';
import { FaFileAlt } from 'react-icons/fa';
import { HiTrash } from 'react-icons/hi';
import { MdEditSquare } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { FinologyTable } from '../../../../components/table/FinologyTable';
import {
  useDeleteDraftReportMutation,
  useGetClientReportsQuery,
} from '../../../../graphql/generated';
import { useFlyoutNavigate } from '../../../../hooks/useFlyoutNavigate';
import { formatDate } from '../../../../utils';

interface Props {
  clientId: number;
}

export const Reports = ({ clientId }: Props) => {
  const { data } = useGetClientReportsQuery({
    variables: { clientId },
  });

  const [deleteReport] = useDeleteDraftReportMutation();

  const { navigate } = useFlyoutNavigate();
  const regularNavigate = useNavigate();

  const reportsColumns = [
    {
      title: 'REPORT NAME',
      dataIndex: 'name',
      key: 'name',
      className: 'w-30',
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
      render: (text: string) => formatDate(text),
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      className: 'w-20',
      render: (_: any, record: any) => {
        return (
          <div className="flex gap-2 items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <div className="flex items-center justify-center rounded-full bg-gray-200 h-7 w-7 cursor-pointer hover:opacity-40">
              <HiTrash
                size={16}
                onClick={async () => {
                  // TODO: add confirmation modal
                  await deleteReport({
                    variables: {
                      input: {
                        id: record!.id,
                      },
                    },
                    refetchQueries: ['GetClientReports'],
                  });
                }}
              />
            </div>

            <div className="flex items-center justify-center rounded-full bg-gray-200 h-7 w-7 cursor-pointer hover:opacity-40">
              <MdEditSquare
                size={16}
                onClick={() =>
                  navigate('create-report', `clientId=${record.clientId}&reportId=${record.id}`)
                }
              />
            </div>
            {/* <Button
              onClick={() => {}}
              color="secondaryLight"
              size="sm"
              className=" whitespace-nowrap p-0"
            >
              Open PDF
            </Button> */}
          </div>
        );
      },
    },
  ];

  return (
    <div className="py-8">
      <div className=" max-w-xl">
        <FinologyTable
          type={'no-promise'}
          tableHeadingLabel="Reports"
          emptyText={() => (
            <div className="flex flex-col items-center justify-center py-16 px-4">
              <div className=" mb-4">
                <FaFileAlt className="text-gray-400" size={40} />
              </div>
              <h3 className="text-xl font-semibold text-gray-500 mb-2">No Reports Added Yet</h3>
              <p className="text-gray-500 text-center mb-6">
                This table will be automatically populated after you save a Liability Planner
                simulation as a report.
              </p>
              <div className="flex gap-3">
                <Button
                  color="secondaryLight"
                  onClick={() => regularNavigate(`${location.pathname}/calculator`)}
                >
                  Go To Liability Planner
                </Button>
              </div>
            </div>
          )}
          columns={reportsColumns}
          rows={data?.clientReports?.items || []}
          rowClassName="group"
        />
      </div>
    </div>
  );
};
