import FinologyCard from '../../components/FinologyCard';
import { useGetAdvisorDashboardQuery } from '../../graphql/generated';
import { toDollars } from '../../util/currency.formatter';

export const DashboardLoanDetails = () => {
  const { data, loading } = useGetAdvisorDashboardQuery();

  const numberOfClients = data?.userDashboard.totalClients ?? 0;
  const totalPrivateDebt = data?.userDashboard?.totalPrivateDebt ?? 0;
  const totalFederalDebt = data?.userDashboard?.totalFederalDebt ?? 0;
  const totalCreditCardDebt = data?.userDashboard?.totalCreditCardDebt ?? 0;
  const totalBalance = totalPrivateDebt + totalFederalDebt;

  return (
    <div className="flex justify-between gap-4 max-w-[1500px]">
      <FinologyCard
        title="Balance"
        totalAmount={toDollars(totalBalance)}
        averageAmount={toDollars(totalBalance / numberOfClients)}
        variant="default"
        isLoading={loading}
      />{' '}
      <FinologyCard
        title="Federal Loans"
        totalAmount={toDollars(totalFederalDebt)}
        averageAmount={toDollars(totalFederalDebt / numberOfClients)}
        variant="blue"
        isLoading={loading}
      />
      <FinologyCard
        title="Private Loans"
        totalAmount={toDollars(totalPrivateDebt)}
        averageAmount={toDollars(totalPrivateDebt / numberOfClients)}
        variant="green"
        isLoading={loading}
      />
      <FinologyCard
        title="Credit Cards"
        totalAmount={toDollars(totalCreditCardDebt)}
        averageAmount={toDollars(totalCreditCardDebt / numberOfClients)}
        variant="orange"
        isLoading={loading}
      />
    </div>
  );
};
